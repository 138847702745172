/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import { getPlatformList } from '@api/node/node';

// ===================================================================
// [ 아이템 구매 증명 신청 (1) - 양식 선택 ]
// ===================================================================
export default React.memo(function Application(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // KYC 인증 유무
  const location = useLocation();
  const isConfirmed = location.state?.isConfirmed || '';

  // 플랫폼 리스트
  const [platformList, setPlatformList] = useState([]);

  // 플랫폼 선택
  const [selectedPlatform, setSelectedPlatform] = useState('');

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = text => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 아이템 구매 증명 양식 선택
  const platformSelectHandler = platform => {
    setSelectedPlatform(platform);
  };

  // 아이템 구매 증명 양식 한글명 추출
  const filterPlatformName = platform => {
    return platformList.filter(item => item.type === platform)[0].name;
  };

  // 아이템 신청 페이지 이동
  const moveAddApplication = () => {
    if (!selectedPlatform) {
      openModal({ text: '아이템 구매 증명 양식이 선택되지 않았습니다.' });
    } else {
      history.push({
        pathname: `/status/addapplication`,
        state: {
          platform: selectedPlatform,
          platformHan: filterPlatformName(selectedPlatform),
        },
      });
    }
  };

  // 뒤로가기
  const moveBack = () => {
    history.go(-1);
  };

  // ===================================================================
  // [ API ] 노드 플랫폼 리스트
  // ===================================================================
  const loadPlatformList = async () => {
    // Axios
    try {
      const { data } = await getPlatformList();

      // 성공
      if (data.code === '0000') {
        const emptyList = [];
        const list = data.data || [];

        for (let i = 0; i < list.length; i += 1) {
          if (list[i].useYn === 'Y') {
            emptyList.push({
              type: list[i].msCd,
              name: list[i].msNm,
              sort: list[i].msSort,
            });
          }
        }

        setPlatformList(emptyList);
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsConfirmed(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================

  useEffect(() => {
    // 접근 유효성 확인
    if (!isConfirmed || isConfirmed !== 'Y') {
      openModal('잘못된 접근입니다.');
    }
    // 플랫폼 리스트 확인
    else {
      loadPlatformList();
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="application">
      {/* 헤더 */}
      <Header
        title="본인 계정 구매 아이템 승인신청"
        onClickBtn={() => history.push('sharestatus')}
      />

      {/* 컨텐츠 */}
      <Container className="container-between">
        <Form className="scroll">
          {/* 타이틀 */}
          <div className="title-agree">
            <h4>양식선택</h4>
            <p>본인 계정 구매 아이템 증명을 위한 양식을 선택해주세요.</p>
          </div>

          {/* 플랫폼 리스트 */}
          <div className="flatform">
            {platformList.length > 0 &&
              platformList.map(item => {
                return (
                  <div
                    key={`platform-list-${item.sort}`}
                    className={`formbox ${
                      selectedPlatform === item.type ? 'selected' : ''
                    }`}
                    onClick={() => platformSelectHandler(item.type)}
                  >
                    {item.name}
                  </div>
                );
              })}
          </div>
        </Form>

        {/* 다음 */}
        <div className="btn-area fix-bottom">
          <Button
            variant="outline-primary"
            disabled={!selectedPlatform}
            onClick={moveAddApplication}
          >
            다음
          </Button>
        </div>
      </Container>

      {/* 메세지 모달 */}
      <CustomModal
        headerDisplay={false}
        contentText={modalText}
        display={modalShow}
        onHide={closeModal}
        onConfirm={moveBack}
      />
    </main>
  );
});
