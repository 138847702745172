import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';

// ===================================================================
// [ Home Footer ]
// ===================================================================
export default React.memo(function Navi() {
  const history = useHistory();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleNavClick = path => {
    setActiveLink(path);
    history.push(path);
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <Nav as="nav">
      {/* 아이템 현황 */}
      <Nav.Item>
        <Nav.Link
          className={activeLink === '/sharestatus' ? 'active' : ''}
          onClick={() => handleNavClick('/sharestatus')}
        >
          <i className="material-icons">list_alt</i>
          <span>아이템</span>
        </Nav.Link>
      </Nav.Item>

      {/* 지갑 */}
      <Nav.Item>
        <Nav.Link
          className={activeLink === '/wallet' ? 'active' : ''}
          onClick={() => handleNavClick('/wallet')}
        >
          <i className="material-icons">account_balance_wallet</i>
          <span>지갑</span>
        </Nav.Link>
      </Nav.Item>

      {/* 보유자산 */}
      <Nav.Item>
        <Nav.Link
          className={activeLink === '/my-assets' ? 'active' : ''}
          onClick={() => handleNavClick('/my-assets')}
        >
          <i className="material-icons">savings</i>
          <span>보유자산</span>
        </Nav.Link>
      </Nav.Item>

      {/* 더보기 - 내정보, 인증센터 */}
      <Nav.Item>
        <Nav.Link
          className={
            activeLink === '/myinfo' || activeLink === '/status/authcenter'
              ? 'active'
              : ''
          }
          onClick={() => handleNavClick('/myinfo')}
        >
          <i className="material-icons">more_horiz</i>
          <span>더보기</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
});
