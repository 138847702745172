import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_ASSET = '/api/v1/asset';

const ASSET_LIST = `${API_ASSET}/list`;
const ASSET_ASSET_DETAIL = `${API_ASSET}/assetDetail`;
const KRW_FEE_INFO = `${API_ASSET}/getKrwFeeInfo`;
const ASSET_CANCEL_WITHDRAW_REQUEST = `${API_ASSET}/cancelWithdrawalRequest`;
const ASSET_WITHDRAWAL_REQUEST = `${API_ASSET}/withdrawalRequest`;
const ASSET_TRANSACTION_HISTORY = `${API_ASSET}/transactionHistory`;

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 자산 목록
export const getAssetList = () => {
  return AXIOS.post(`${ASSET_LIST}`);
};

// 자산 상세
export const getAssetDetail = data => {
  return AXIOS.post(`${ASSET_ASSET_DETAIL}`, data);
};

// 자산 상세
export const getKrwFee = data => {
  return AXIOS.get(`${KRW_FEE_INFO}`, data);
};

// 출금 요청 취소
export const cancelWithdrawalRequest = data => {
  return AXIOS.post(`${ASSET_CANCEL_WITHDRAW_REQUEST}/${data}`);
};

// 출금 요청
export const withdrawalRequest = data => {
  return AXIOS.post(`${ASSET_WITHDRAWAL_REQUEST}`, data);
};

// 입출금 내역
export const transactionHistory = data => {
  return AXIOS.get(`${ASSET_TRANSACTION_HISTORY}`, { params: data });
};
