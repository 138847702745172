/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line prettier/prettier
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { Header, SmallModal, SelfAuthModal, TermsModal } from '@components';

export default React.memo(function Confirmation(props) {
  const history = useHistory();

  return (
    <main id="confirmation">
      {/* <Header title="회원가입" /> */}
      <Header title="회원가입" />
      <Container className="container-between ">
        <Form className="scroll">
          <div className="confirm-box">
            <div className="confirm-kyc">
              <h6>고객확인제도(KYC)란?</h6>
              <p>
                금융기관 등이 제공하는 금융거래 또는 서비스가 자금세탁 등의
                불법행위에 이용되지 않도록 회원님의 신원사항, 거래 목적 및 자금
                출처, 실제 소유자 등을 확인하는 제도입니다.
              </p>
            </div>
            <div className="confirm-ready mt-3">
              <h6>고객확인제도(KYC) 진행 준비사항</h6>
              <p>신분증(주민등록증,운전면허증 또는 여권)</p>
              <p>본인 명의의 계좌</p>
            </div>
          </div>
        </Form>
        <div className="btn-area">
          <Button variant="outline-primary" className="btn-wd-40">
            건너뛰기
          </Button>
          <Button
            className="btn-wd-60"
            onClick={() => {
              history.push({ pathname: `/auth/bankauth` });
            }}
          >
            인증하기
          </Button>
        </div>
      </Container>
    </main>
  );
});
