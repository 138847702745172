/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { MainHeader, Navi } from '@components';

// API
import { getWalletList } from '@api/wallet/wallet';
import { getMyKyc } from '@api/auth/auth';
import { getBank } from '@api/bank/bank';

// 지갑 초기 객체
const initialWalletInfo = {
  seq: '',
  address: '',
  status: '',
  statusHan: '',
  modDate: '',
  regDate: '',
  confirmDate: '',
};

// KYC 초기 객체
const initialKycInfo = {
  seq: '',
  type: '',
  mbFullName: '',
  status: '',
  statusHan: '',
  confirmDate: '',
  regDate: '',
};

// KYC 초기 객체
const initialBankInfo = {
  seq: '',
  address: '',
  bankName: '',
  bankOwner: '',
  bankCode: '',
  status: '',
  statusHan: '',
  modDate: '',
  regDate: '',
  confirmDate: '',
};

// KYC 초기 스타일 & 라벨 & 이벤트
const initialKycOptions = {
  label: '미등록',
  style: '',
  fn: null,
};

// 지갑 초기 스타일 & 라벨 & 이벤트
const initialWalletOptions = {
  label: '',
  style: '',
  fn: null,
};

// 지갑 초기 스타일 & 라벨 & 이벤트
const initialBankOptions = {
  label: '',
  style: '',
  fn: null,
};

// ===================================================================
// [ 인증센터 - 지갑, KYC 등록 현황 ]
// ===================================================================
export default React.memo(function AuthCenter(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 지갑
  const [walletInfo, setWalletInfo] = useState(initialWalletInfo);
  const [walletOptions, setWalletOptions] = useState(initialWalletOptions);

  // KYC
  const [kycInfo, setKycInfo] = useState(initialKycInfo);
  const [kycOptions, setKycOptions] = useState(initialKycOptions);

  // 은행 계좌
  const [bankInfo, setBankInfo] = useState(initialBankInfo);
  const [bankOptions, setBankOptions] = useState(initialBankOptions);

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 탭 변경
  const tabChangeHandler = key => {
    if (key === '내정보') moveMyInfo();
  };

  // 내정보 이동
  const moveMyInfo = () => {
    history.push('/myinfo');
  };

  // 지갑 상세 이동
  const moveWallet = () => {
    history.push('/wallet');
  };

  // 지갑등록 이동
  const moveAddWallet = () => {
    history.push('/wallet/addwallet');
  };

  // KYC 인증 이동
  const moveAddKyc = () => {
    history.push('/identify/check');
  };

  // KYC 인증 상세 이동
  const moveKycDetail = () => {
    history.push({ pathname: '/identify/clear', state: { kycInfo } });
  };

  // 계좌 상세 이동
  const moveBank = () => {
    history.push('/account/clear');
  };

  // 계좌등록 이동
  const moveAddBank = () => {
    history.push('/account/check'); // 이동할 경로
  };

  // KYC, 지갑 상태별 스타일 변환 ( null = 미등록, complete = 등록완료, cancle = 반려, stay 승인대기 )
  const convertOptions = (type, status) => {
    // KYC
    if (type === 'kyc') {
      switch (status) {
        // 승인대기
        case 'REQ':
          kycOptions.label = '승인대기';
          kycOptions.style = 'stay';
          kycOptions.fn = moveKycDetail;
          break;
        // 승인
        case 'OK':
          kycOptions.label = '승인';
          kycOptions.style = 'complete';
          kycOptions.fn = moveKycDetail;
          break;
        // 반려
        case 'REJ':
          kycOptions.label = '반려';
          kycOptions.style = 'cancle';
          kycOptions.fn = moveKycDetail;
          break;
        default:
          kycOptions.label = '미등록';
          kycOptions.style = '';
          kycOptions.fn = moveAddKyc;
          break;
      }

      setKycOptions({ ...kycOptions });
    }

    // 지갑
    else if (type === 'wallet') {
      switch (status) {
        // 승인대기
        case 'REQ':
          walletOptions.label = '승인대기';
          walletOptions.style = 'stay';
          walletOptions.fn = moveWallet;
          break;
        // 승인
        case 'OK':
          walletOptions.label = '승인';
          walletOptions.style = 'complete';
          walletOptions.fn = moveWallet;
          break;
        // 반려
        case 'REJ':
          walletOptions.label = '반려';
          walletOptions.style = 'cancle';
          walletOptions.fn = moveWallet;
          break;
        default:
          walletOptions.label = '미등록';
          walletOptions.style = '';
          walletOptions.fn = moveAddWallet;
          break;
      }

      setWalletOptions({ ...walletOptions });
    }

    // 은행 계좌 주소
    else if (type === 'bank') {
      switch (status) {
        // 승인대기
        case 'REQ':
          bankOptions.label = '승인대기';
          bankOptions.style = 'stay';
          bankOptions.fn = moveBank;
          break;
        // 승인
        case 'OK':
          bankOptions.label = '승인';
          bankOptions.style = 'complete';
          bankOptions.fn = moveBank;
          break;
        // 반려
        case 'REJ':
          bankOptions.label = '반려';
          bankOptions.style = 'cancle';
          bankOptions.fn = moveBank;
          break;
        default:
          bankOptions.label = '미등록';
          bankOptions.style = '';
          bankOptions.fn = moveAddBank;
          break;
      }

      setBankOptions({ ...bankOptions });
    }
  };

  // ===================================================================
  // [ API ] 지갑 등록 현황
  // ===================================================================
  const getMyWalletStatus = async () => {
    // Axios
    try {
      const { data } = await getWalletList();

      // 성공
      if (data.code === '0000') {
        setWalletInfo({
          seq: data.data?.seq || '',
          address: data.data?.address || '',
          status: data.data?.status || '',
          statusHan: data.data?.statusHan || '',
          confirmDate: data.data?.confirmDate || '',
          regDate: data.data?.regDate || '',
        });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsConfirmed(false);
    }
  };

  // ===================================================================
  // [ API ] KYC 인증현황
  // ===================================================================
  const getMyKycStatus = async () => {
    // Axios
    try {
      const { data } = await getMyKyc();

      // 성공
      if (data.code === '0000') {
        if (data.data) {
          // KYC 데이터 주입
          setKycInfo({
            seq: data.data.seq,
            type: data.data.type,
            mbFullName: data.data.mbFullName,
            status: data.data.status,
            statusHan: data.data.statusHan,
            confirmDate: data.data.confirmDate,
            memo: data.data.memo,
            regDate: data.data.regDate,
          });
        }
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsConfirmed(false);
    }
  };

  // ===================================================================
  // [ API ] KYC 인증현황
  // ===================================================================
  const getMyBankStatus = async () => {
    // Axios
    try {
      const { data } = await getBank();
      // 성공
      if (data.code === '0000') {
        if (data.data) {
          // KYC 데이터 주입
          setBankInfo({
            seq: data.data.seq,
            address: data.data.address,
            bankName: data.data.bankName,
            bankOwner: data.data.bankOwner,
            bankCode: data.data.bankCode,
            status: data.data.status,
            statusHan: data.data.statusHan,
            confirmDate: data.data.confirmDate,
            regDate: data.data.regDate,
          });
        }
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsConfirmed(false);
    }
  };
  // ===================================================================
  // [ useEffect ]
  // ===================================================================

  // KYC, 지갑 정보 조회
  useEffect(() => {
    getMyWalletStatus();
    getMyKycStatus();
    getMyBankStatus();

    return () => {};
  }, []);

  // KYC 상태 및 라벨 설정
  useEffect(() => {
    convertOptions('kyc', kycInfo.status);
    return () => {};
  }, [kycInfo]);

  // 지갑 상태 및 라벨 설정
  useEffect(() => {
    convertOptions('wallet', walletInfo.status);
    return () => {};
  }, [walletInfo]);

  // 지갑 상태 및 라벨 설정
  useEffect(() => {
    convertOptions('bank', bankInfo.status);
    return () => {};
  }, [bankInfo]);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="authcenter">
      {/* 헤더 */}
      <MainHeader />

      {/* 컨텐츠 */}
      <Container className="container-custom">
        {/* 메뉴 탭 */}
        <div id="Tab-css">
          <Tabs
            defaultActiveKey="인증센터"
            justify
            onSelect={activeKey => tabChangeHandler(activeKey)}
          >
            <Tab eventKey="내정보" title="내정보" />
            <Tab eventKey="인증센터" title="인증센터" />
          </Tabs>
        </div>

        {/* 안내문구 */}
        <div className="confirm-pd">
          <div className="confirm-box">
            <div className="confirm-kyc">
              <h6>고객확인제도(KYC)란?</h6>
              <p>
                금융기관 등이 제공하는 금융거래 또는 서비스가 자금세탁 등의
                불법행위에 이용되지 않도록 회원님의 신원사항, 거래 목적 및 자금
                출처, 실제 소유자 등을 확인하는 제도입니다.
              </p>
            </div>
            <div className="confirm-ready mt-3">
              <h6>고객확인제도(KYC) 진행 준비사항</h6>
              <p>신분증(주민등록증 ,운전면허증 ,여권)</p>
              <p>본인 명의의 계좌</p>
            </div>
          </div>
        </div>

        {/* 지갑 정보 */}
        <div className="auth-box" onClick={walletOptions.fn}>
          <div className="auth-content">
            <div className="text-box">
              {/* 라벨 */}
              <p className="auth-title">지갑 등록</p>

              {/* 상태 */}
              <p className={`label ${walletOptions.style}`}>
                {walletOptions.label}
              </p>
            </div>
            <i className="material-icons">arrow_forward_ios</i>
          </div>
        </div>

        {/* KYC 정보 */}
        <div className="auth-box" onClick={kycOptions.fn}>
          <div className="auth-content">
            <div className="text-box">
              {/* 라벨 */}
              <p className="auth-title">신분증 인증</p>

              {/* 상태 */}
              <p className={`label ${kycOptions.style}`}>{kycOptions.label}</p>
            </div>
            <i className="material-icons">arrow_forward_ios</i>
          </div>
        </div>

        {/* 은행 계좌 정보 */}
        <div className="auth-box" onClick={bankOptions.fn}>
          <div className="auth-content">
            <div className="text-box">
              {/* 라벨 */}
              <p className="auth-title">은행 계좌 등록</p>

              {/* 상태 */}
              <p className={`label ${bankOptions.style}`}>
                {bankOptions.label}
              </p>
            </div>
            <i className="material-icons">arrow_forward_ios</i>
          </div>
        </div>
      </Container>

      {/* 푸터 */}
      <Navi />
    </main>
  );
});
