import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_MORE_AUTH = '/api/v1/open/more';

const GET_CLAUSE = `${API_MORE_AUTH}/getClause`;
const GET_CLAUSE_LIST = `${API_MORE_AUTH}/getClauseList`;
const GET_GUIDE = `${API_MORE_AUTH}/getGuide`;

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 약관 가져오기
export const getClause = data => {
  return AXIOS.post(`${GET_CLAUSE}`, data);
};

// 약관 가져오기
export const getClauseList = data => {
  return AXIOS.get(`${GET_CLAUSE_LIST}`, { params: data });
};

export const getGuide = () => {
  return AXIOS.post(`${GET_GUIDE}`);
};
