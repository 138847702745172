import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_USER = '/api/v1/user';

const GET_USER_SIMPLE_INFO = `${API_USER}/getUserSimpleInfo`;
const GET_USER_CERTIFY_INFO = `${API_USER}/getUserCertifyInfo`;

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 유저 기본 정보 조회
export const getUserSimpleInfo = () => {
  return AXIOS.get(`${GET_USER_SIMPLE_INFO}`);
};

// 유저 인증 정보 조회
export const getUserCertifyInfo = () => {
  return AXIOS.get(`${GET_USER_CERTIFY_INFO}`);
};
