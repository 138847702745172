import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_NODE = '/api/v1/node';

const NODE_INFO = `${API_NODE}/`;
const NODE_LIST = `${API_NODE}/applyList`;
const NODE_PLATFORM_LIST = `${API_NODE}/platformList`;
const NODE_CHECK = `${API_NODE}/applyCheck`;
const NODE_APPLY = `${API_NODE}/apply`;
const NODE_APPLY_WITH_SEQ = `${API_NODE}/apply`;

/* ****************************************************** */
// HTTP HEADER
/* ****************************************************** */

//* 첨부 파일이 있을 경우 ( Content-Type > multipart/form-data )
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 아이템 현황
export const getNodeInfo = () => {
  return AXIOS.post(`${NODE_INFO}`);
};

// 아이템 처리 목록 출력
export const getNodeList = data => {
  return AXIOS.post(`${NODE_LIST}`, data);
};

// 플랫폼 리스트
export const getPlatformList = () => {
  return AXIOS.get(`${NODE_PLATFORM_LIST}`);
};

// 본인 계정 구매 아이템 승인신청 확인
export const checkNode = () => {
  return AXIOS.post(`${NODE_CHECK}`);
};

// 아이템 신청
export const applyNode = data => {
  return AXIOS.post(`${NODE_APPLY}`, data, formDataConfig);
};

// 아이템 처리 내역 상세 출력
export const getNodeDetailBySeq = seq => {
  return AXIOS.post(`${NODE_APPLY_WITH_SEQ}/${seq}`);
};
