import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_MORE = '/api/v1/more';

const MEMBER_INFO = `${API_MORE}/memberInfo`;
const EDIT_PASSWORD_COMPLETE = `${API_MORE}/editPasswordComplete`;
const SEND_CODE_FOR_EDIT_PHONE_NO = `${API_MORE}/sendCodeForEditPhoneNo`;
const CHECK_CODE_FOR_EDIT_PHONE_NO = `${API_MORE}/checkCodeForEditPhoneNo`;
const EDIT_PHONE_NO_COMPLETE = `${API_MORE}/editPhoneNoComplete`;
const NOTICE_LIST = `${API_MORE}/noticeList`;
const NOTICE_DETAIL = `${API_MORE}/noticeDetail`;
const PREV_NOTICE = `${API_MORE}/prevNotice`;
const NEXT_NOTICE = `${API_MORE}/nextNotice`;
const GET_GUIDE = `${API_MORE}/getGuide`;

/* ****************************************************** */
// HTTP HEADER
/* ****************************************************** */

//* 첨부 파일이 있을 경우 ( Content-Type > multipart/form-data )
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 회원 기본정보 출력
export const memberInfo = data => {
  return AXIOS.post(`${MEMBER_INFO}`, data);
};

// 비밀번호 변경 완료
export const editPasswordComplete = data => {
  return AXIOS.post(`${EDIT_PASSWORD_COMPLETE}`, data);
};

// 인증번호 발송 (전화번호 수정)
export const sendCodeForEditPhoneNo = data => {
  return AXIOS.post(`${SEND_CODE_FOR_EDIT_PHONE_NO}`, data);
};

// 인증번호 확인 (전화번호 수정)
export const checkCodeForEditPhoneNo = data => {
  return AXIOS.post(`${CHECK_CODE_FOR_EDIT_PHONE_NO}`, data);
};

// 전화번호 수정 완료
export const editPhoneNoComplete = data => {
  return AXIOS.post(`${EDIT_PHONE_NO_COMPLETE}`, data);
};

// 공지사항 리스트
export const getNoticeList = data => {
  return AXIOS.post(`${NOTICE_LIST}`, data);
};

// 공지사항 상세
export const getNoticeDetail = data => {
  return AXIOS.post(`${NOTICE_DETAIL}`, data);
};

// 이전 공지사항
export const getPrevNotice = data => {
  return AXIOS.post(`${PREV_NOTICE}`, data);
};

// 다음 공지사항
export const getNextNotice = data => {
  return AXIOS.post(`${NEXT_NOTICE}`, data);
};

// 다음 공지사항
export const getGuide = () => {
  return AXIOS.post(`${GET_GUIDE}`);
};
