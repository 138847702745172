/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { Header, SmallModal } from '@components';
import { useHistory } from 'react-router-dom';

export default React.memo(function BankAccountAuth(props) {
  const history = useHistory();
  return (
    <main id="account-auth">
      {/** 헤더에 x표시말고는 다 제거해주세요.. */}
      <Header title="은행 계좌 인증" />
      <Container className="container-between">
        <Form className="scroll mt-4">
          <div className="account-box">
            <div className="title-box">
              <p className="title-account">실명확인 계좌 인증 정보</p>
            </div>
            <div className="account-btnbox">
              <Button
                className="auth-btn"
                onClick={() => {
                  history.push({ pathname: `/account/check` });
                }}
              >
                등록
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </main>
  );
});
