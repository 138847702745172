/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line prettier/prettier
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';

// Custom Component
import { Header, HtmlModal } from '@components';

// API
import { getClauseList } from '@api/more/openMore';

// ===================================================================
// [ 회원가입 약관 ]
// ===================================================================
export default React.memo(function SignUp(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 약관 리스트
  const [useClauseList, setUseClauseList] = useState([]);
  const [notUseClauseList, setNotUseClauseList] = useState([]);

  // 전체 동의
  const [allAgreeChecked, setAllAgreeChecked] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalHeaderText, setModalHeaderText] = useState('');
  const [modalBodyText, setModalBodyText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ head, body }) => {
    setModalHeaderText(head);
    setModalBodyText(body);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 전체 동의 체크
  const allAgreementCheckHandler = checked => {
    setAllAgreeChecked(checked);

    // 개별 약관 변경
    useClauseList.map(item => {
      item.isChecked = checked;
      return {
        ...item,
      };
    });

    setUseClauseList([...useClauseList]);
  };

  // 개별 약관 체크
  const agreementCheckHandler = (idx, checked) => {
    useClauseList[idx].isChecked = checked;
    setUseClauseList([...useClauseList]);

    // 전체 동의 체크
    setAllAgreeChecked(isAllChecked());
  };

  // 전체 동의 확인
  const isAllChecked = () => {
    const filteredList = useClauseList.filter(item => !item.isChecked);
    return filteredList.length === 0;
  };

  // 필수 동의 확인
  const isRequiredChecked = () => {
    const requiredList = useClauseList.filter(item => item.requireYn === 'Y');
    const filteredList = requiredList.filter(item => item.isChecked);
    return requiredList.length === filteredList.length;
  };

  // 약관 데이터 취합 ( 사용 + 미사용 )
  const summaryAgreementList = () => {
    // 사용 체크리스트
    const checkList = useClauseList.map(item => {
      return {
        type: item.type,
        isChecked: item.isChecked ? 'Y' : 'N',
      };
    });

    // 미사용 체크리스트
    const noCheckList = notUseClauseList.map(item => {
      return {
        type: item.type,
        isChecked: item.isChecked ? 'Y' : 'N',
      };
    });

    // 취합
    return [...checkList, ...noCheckList];
  };

  // ===================================================================
  // [ API ] 약관 상세 내용 가져오기
  // ===================================================================
  const getAgreementList = async () => {
    // JSON 파라미터
    const params = {
      lang: 'KO',
    };

    // Axios
    try {
      const { data } = await getClauseList(params);

      // 성공
      if (data.code === '0000') {
        const list = data.data.map(item => {
          item.isChecked = false;
          return {
            ...item,
          };
        });

        setUseClauseList(list.filter(item => item.useFlag === 'Y')); // 사용 체크리스트
        setNotUseClauseList(list.filter(item => item.useFlag === 'N')); // 미사용 체크리스트
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getAgreementList();

    return () => {};
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="sign-up">
      {/* 헤더 */}
      <Header title="회원가입" />

      {/* 컨텐츠 */}
      <Container className="container-between ">
        <Form className="scroll">
          <div className="title-agree">
            <h4>약관동의</h4>
            <p>필수항목 및 선택항목 약관에 동의해 주세요.</p>
          </div>

          {/* 약관 목록 */}
          <div className="mt-5 agreebox">
            {/* 전체 동의 */}
            <Form.Group
              className={`all-agree ${
                allAgreeChecked ? 'all-agree-checked' : ''
              }`}
            >
              <Form.Check
                type="checkbox"
                label="전체동의"
                id="allagree"
                value="allagree"
                onChange={e => allAgreementCheckHandler(e.target.checked)}
                checked={allAgreeChecked}
              />
            </Form.Group>

            {/* 약관 리스트 */}
            <div className="agree-field">
              {useClauseList.length > 0 &&
                useClauseList.map((item, idx) => {
                  return (
                    <Form.Group key={`agreement-${idx + 1}`} className="mt-b8">
                      <Form.Check
                        type="checkbox"
                        label={`(${item.requireYn === 'Y' ? '필수' : '선택'}) ${
                          item.title
                        }`}
                        id={`agree${idx}`}
                        value={`agree${idx}`}
                        checked={useClauseList[idx].isChecked}
                        onChange={e =>
                          agreementCheckHandler(idx, e.target.checked)
                        }
                      />
                      <Button
                        className="btn-next"
                        onClick={() =>
                          openModal({
                            head: item.title,
                            body: item.contents,
                          })
                        }
                      />
                    </Form.Group>
                  );
                })}
            </div>
          </div>
        </Form>

        {/* 다음 */}
        <div className="btn-area">
          <Button
            className="w-100"
            disabled={!isRequiredChecked()}
            onClick={e => {
              history.push({
                pathname: `/auth/infor`,
                state: summaryAgreementList(),
              });
            }}
          >
            다음
          </Button>
        </div>
      </Container>

      {/* 약관 모달 */}
      <HtmlModal
        headerText={modalHeaderText}
        contentHtml={modalBodyText}
        display={modalShow}
        onHide={closeModal}
      />
    </main>
  );
});
