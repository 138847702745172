/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import { resetPassword } from '@api/auth/openAuth';

// ===================================================================
// [ 비밀번호 재설정 ]
// ===================================================================
export default React.memo(function FindId(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,12}$/;

  // 쿼리 파라미터
  const urlParams = new URLSearchParams(window.location.search);
  const mbIdx = urlParams.get('idx');
  const verificationCode = urlParams.get('code');
  const verificationSeq = urlParams.get('seq');

  // 비밀번호
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // 비밀번호 유효성
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] =
    useState(false);

  // 중복 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text, type = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 새 비밀번호 입력
  const pwdInputHandler = e => {
    setPassword(e.target.value);
  };

  // 새 비밀번호 재입력
  const confirmPwdInputHandler = e => {
    setConfirmPassword(e.target.value);
  };

  // 새 비밀번호 입력값 검증
  const validatePassword = () => {
    setIsPasswordInvalid(!passwordRegex.test(password));
  };

  // 새 비밀번호 재입력값 검증
  const validateConfirmPassword = () => {
    setIsConfirmPasswordInvalid(!(password === confirmPassword));
  };

  // 이전 페이지 이동
  const movePrevPage = () => {
    history.go(-1);
  };

  // 로그인 페이지 이동
  const moveLoginPage = () => {
    history.push({ pathname: `/` });
  };

  // ===================================================================
  // [ API ] 비밀번호 재설정
  // ===================================================================
  const resetPwd = async () => {
    // 중복 방지
    if (isSentRequest) {
      openModal({ text: '진행중인 요청입니다.' });
      return;
    }

    // Input 확인
    if (
      !password ||
      !confirmPassword ||
      isPasswordInvalid ||
      isConfirmPasswordInvalid
    ) {
      openModal({ text: '새 비밀번호를 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      mbIdx,
      password,
      certificationSeq: verificationSeq,
      certificationCode: verificationCode,
    };

    // 중복 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await resetPassword(params);

      // 성공
      if (data.code === '0000') {
        openModal({ text: '비밀번호가 재설정되었습니다.', type: 'complete' });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    if (!mbIdx || !verificationSeq || !verificationCode) {
      openModal({ text: '잘못된 접근입니다.', type: 'denied' });
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="pwdnew">
      {/* 헤더 */}
      <Header title="비밀번호 재설정" />

      {/* 컨텐츠 */}
      <Container className="container-between">
        <Form className="scroll mt-4">
          <p className="id-check">비밀번호를 재설정해 주세요.</p>

          <Form.Group className="mt-4">
            {/* 새 비밀번호 */}
            <Form.Label>새 비밀번호</Form.Label>
            <Form.Control
              type="password"
              placeholder="영문+숫자 4~12자 이내"
              value={password}
              onChange={pwdInputHandler}
              onBlur={validatePassword}
              isInvalid={isPasswordInvalid}
            />
            {isPasswordInvalid && (
              <Form.Control.Feedback type="invalid">
                영문/숫자 조합(4~12자)
              </Form.Control.Feedback>
            )}

            {/* 새 비밀번호 확인 */}
            <Form.Control
              type="password"
              placeholder="새 비밀번호 확인"
              value={confirmPassword}
              onChange={confirmPwdInputHandler}
              onBlur={validateConfirmPassword}
              isInvalid={isConfirmPasswordInvalid}
            />
            {isConfirmPasswordInvalid && (
              <Form.Control.Feedback type="invalid">
                비밀번호가 일치하지 않습니다.
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form>

        {/* 비밀번호 재설정 */}
        <div className="btn-area fix-bottom">
          <Button
            size="lg"
            disabled={
              !password ||
              !confirmPassword ||
              isConfirmPasswordInvalid ||
              isPasswordInvalid
            }
            onClick={resetPwd}
          >
            비밀번호 재설정
          </Button>
        </div>
      </Container>

      {/* 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
        onConfirm={
          modalType === 'complete'
            ? moveLoginPage
            : modalType === 'denied'
            ? movePrevPage
            : null
        }
      />
    </main>
  );
});
