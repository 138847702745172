/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { WithdrawHeader, CustomModal, CustomSelect } from '@components';
import { useHistory } from 'react-router-dom';
import { getNoticeList } from '@api/more/more';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getAssetDetail, getKrwFee } from '../../api/asset/asset';
import utils from '../../common/Utils';

// ===================================================================
// [ 보유자산 상세 ]
// ===================================================================
export default React.memo(function AssetsWithdraw(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [changeMoney, setChangeMoney] = useState(0);
  const [accountInfo, setAccountInfo] = useState({});
  const history = useHistory();
  const location = useLocation();
  const [check, setCheck] = useState(false);

  // 메세지 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState('');
  const [krwFee, setKrwFee] = useState(1000);

  const [modalShow2, setModalShow2] = useState(false);
  const [modalText2, setModalText2] = useState('');

  const platform =
    location.state && location.state.platform ? location.state.platform : '';

  let transactionClass = 'transaction-type';

  if (true) {
    transactionClass += ' withdrawal'; // 출금인 경우에 클래스 추가
  }

  useEffect(() => {
    getAccountInfo();
    getCodeKrwFee();
  }, [location.state]);
  const getAccountInfo = async () => {
    const info = {
      platform,
    };
    const { data } = await getAssetDetail(info);
    setAccountInfo(data.data);
    if (data.data.mbBankAccountNo == null || data.data.mbBankAccountNo === '') {
      setModalShow2(true);
      setModalText2('계좌 인증을 먼저 진행후 출금이 가능 합니다.');
    }
  };
  const getCodeKrwFee = async () => {
    const { data } = await getKrwFee();
    if (data && data.data && data.data[0] && data.data[0].msNm) {
      setKrwFee(data.data[0].msNm);
    } else {
      setKrwFee(1000);
    }
  };

  // JavaScript (또는 React) 코드
  function handleCheckboxChange(event) {
    if (event.target.checked) {
      setChangeMoney(utils.numberComma(accountInfo.exchangeCan));
      setCheck(true);
    } else {
      setChangeMoney(0);
      setCheck(false);
    }
  }

  const handleInputChange = e => {
    const regex = /^[\d,-]+$/;
    console.log(e.target.value);
    if (e.target.value === '' || e.target.value < 0) {
      setChangeMoney(0);
      return;
    }

    if (!regex.test(e.target.value)) {
      openModal({ text: '숫자만 입력이 가능 합니다.' });
      return;
    }

    if (
      utils.removeComma(accountInfo.exchangeCan - krwFee) <
        utils.removeComma(e.target.value) &&
      utils.removeComma(e.target.value) > krwFee
    ) {
      if (accountInfo.exchangeCan - krwFee > 0) {
        setChangeMoney(utils.numberComma(accountInfo.exchangeCan));
        setCheck(true);
      } else {
        setChangeMoney(utils.numberComma(accountInfo.exchangeCan));
      }
    } else {
      setCheck(false);
      setChangeMoney(utils.changeNumberComma(e.target.value));
    }
  };

  // 모달 출력
  const openModal = ({ text, type = '', data = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
    setModalData(data);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // eslint-disable-next-line consistent-return
  const handlePageMove = () => {
    if (utils.removeComma(changeMoney) <= 5000) {
      openModal({ text: '최소 금액이 5000원 이상 되어야 합니다.' });
      return;
    }
    history.push({
      pathname: `/my-assets/withdrawdetail`,
      state: { changeMoney, platform },
    });
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="assets-withdraw">
      <WithdrawHeader title="출금" isCloseBtn={false} />
      <Container className="container-between">
        <Form className="withdraw-box">
          {/* 보유자산 코인 출금 시 활성화 */}
          {/* <Form.Group>
            <Form.Label>지갑주소</Form.Label>
            <Form.Control type="text" placeholder="" />
          </Form.Group> */}

          <div className="withdrawal-possible">
            <p className="title">출금가능</p>
            <p className="amount">
              {utils.numberComma(accountInfo.exchangeCan)}{' '}
              <span>{platform}</span>
            </p>
          </div>

          <div className="withdrawal-possible">
            <p className="title">출금신청수량</p>
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="fullPayment"
                name="fullPayment"
                className="checkbox"
                checked={check}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="fullPayment" className="custom-checkbox">
                <span className="amount" />
                전액사용
              </label>
            </div>
          </div>

          <div className="amount-input">
            <Form.Control
              type="text"
              placeholder="수량 입력"
              value={changeMoney}
              onChange={e => handleInputChange(e)}
            />
            <p>최소 출금 금액 : 5,000 KRW</p>
          </div>

          <div className="withdrawal-total-box">
            <div className="info" style={{ marginBottom: '0.5rem' }}>
              <p className="title">출금</p>
              <p className="amount">
                {utils.removeComma(changeMoney) > krwFee
                  ? utils.numberComma(
                      utils.removeComma(changeMoney) -
                        Math.floor(utils.removeComma(changeMoney) * 0.033) -
                        +krwFee,
                    )
                  : 0}{' '}
                {platform}
              </p>
            </div>
            <div className="info" style={{ marginBottom: '0.5rem' }}>
              <p className="title">수수료</p>
              <p className="amount">
                {utils.numberComma(krwFee)} {platform}
              </p>
            </div>
            <div className="info">
              <p className="title">세금</p>
              <p className="amount">
                {utils.numberComma(
                  Math.floor(utils.removeComma(changeMoney) * 0.033),
                )}{' '}
              </p>
            </div>
            <hr />
            <div className="total-info">
              <p className="title">합계(수수료 포함)</p>
              <p className="amount">
                {changeMoney} {platform}
              </p>
            </div>
          </div>
        </Form>

        <Button
          onClick={() => {
            handlePageMove();
          }}
        >
          다음
        </Button>
      </Container>

      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
      />

      <CustomModal
        contentText={modalText2}
        headerDisplay={false}
        display={modalShow2}
        onHide={() => history.go(-1)}
      />
    </main>
  );
});
