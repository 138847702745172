/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { NoticeHeader } from '@components';
import { useHistory } from 'react-router-dom';
import { getNoticeList } from '@api/more/more';
import InfiniteScroll from 'react-infinite-scroll-component';

const initialPagingParam = {
  pageNo: 1,
  pageSize: 20,
  searchType: '',
  searchTxt: '',
};

// ===================================================================
// [ 공지사항 ]
// ===================================================================
export default React.memo(function Notice(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [pagingParam, setPagingParam] = useState(initialPagingParam);
  const [totalCnt, setTotalCnt] = useState(0);
  const [isLast, setIsLast] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // ===================================================================
  // [ Util ]
  // ===================================================================
  const getNotices = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const { data } = await getNoticeList(pagingParam); // Axios Post Request
        if (data.code === '0000') {
          setIsLast(data.data.isLast);
          setTotalCnt(data.data.totalCount);
          if (!isLast) {
            if (pagingParam.pageNo === 1) {
              setNotices(data.data.contents);
            } else {
              setNotices([...notices, ...data.data.contents]);
            }
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  const DeleteInput = () => {
    setSearchValue('');
  };

  const filteredNotices = notices.filter(notice =>
    notice.title.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const nextEvent = () => {
    setPagingParam(prevParam => ({
      ...prevParam,
      pageNo: prevParam.pageNo + 1,
    }));
  };

  // ===================================================================
  // [ useEffect ] 페이지 로드 시 공지사항 리스트
  // ===================================================================
  useEffect(() => {
    getNotices();
  }, [pagingParam.pageNo]);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="notice-main">
      <NoticeHeader title="공지사항" isCloseBtn={false} />
      <Container className="container-between">
        <Form className="scroll" id="scrollableForm">
          <div className="search-box">
            <Form.Control
              type="text"
              placeholder="검색어 입력"
              value={searchValue}
              onChange={handleInputChange}
            />
            {searchValue ? (
              <Button className="btn delete-input" onClick={DeleteInput} />
            ) : (
              <Button className="btn search" />
            )}
          </div>
          {searchValue ? (
            <div className="notice-line">
              <InfiniteScroll
                className="scroll"
                dataLength={notices.length}
                hasMore={!isLast}
                next={() => {
                  nextEvent();
                }}
                scrollableTarget="scrollableForm"
              >
                {filteredNotices.length > 0 ? (
                  filteredNotices.map(notice => (
                    <div
                      className="notice-box"
                      key={notice.seq}
                      onClick={() => {
                        history.push({
                          pathname: `/myinfo/notice/detail`,
                          search: `?seq=${notice.seq}`,
                        });
                      }}
                    >
                      <div className="notice-textbox">
                        <div className="notice-titlebox">
                          {notice.seq === 1 && notice.title.length > 6 ? (
                            <p className="no-new">N</p>
                          ) : null}
                          <p className="notice-title t-bold">{notice.title}</p>
                        </div>
                        <p className="notice-day">{notice.regDate}</p>
                      </div>
                      <i className="material-icons">arrow_forward_ios</i>
                    </div>
                  ))
                ) : (
                  <p className="no-search-results">검색 결과가 없습니다.</p>
                )}
              </InfiniteScroll>
            </div>
          ) : (
            <div className="notice-line">
              <h6 className="total-text">
                전체 <span className="all-text">{totalCnt}</span>
              </h6>
              <InfiniteScroll
                className="scroll"
                dataLength={notices.length}
                hasMore={!isLast}
                next={() => {
                  nextEvent();
                }}
                scrollableTarget="scrollableForm"
              >
                {notices.map(notice => (
                  <div
                    className="notice-box"
                    key={notice.seq}
                    onClick={() => {
                      history.push({
                        pathname: `/myinfo/notice/detail`,
                        search: `?seq=${notice.seq}`,
                      });
                    }}
                  >
                    <div className="notice-textbox">
                      <div className="notice-titlebox">
                        <p className={`notice-title ${notice.style}`}>
                          {notice.title}
                        </p>
                      </div>
                      <p className="notice-day">{notice.regDate}</p>
                    </div>
                    <i className="material-icons">arrow_forward_ios</i>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Form>
      </Container>
    </main>
  );
});
