/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

// Custom Component
import { Header, CustomModal } from '@components';

// ===================================================================
// [ 공지사항 상세 ]
// ===================================================================
export default React.memo(function noticeDetailPage(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const location = useLocation();

  // 이용가이드 상세 정보
  const guideInfo = {
    seq: location.state?.seq,
    type: location.state?.type,
    title: location.state?.title,
    contents: location.state?.contents,
  };

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    if (!guideInfo.seq) openModal({ text: '잘못된 접근입니다.' });
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="guide-detail">
      {/* 헤더 */}
      <Header title={guideInfo.title} onClickBtn={false} />

      {/* 컨텐츠 */}
      <Container className="container-between">
        <div
          className="scroll detail-body"
          dangerouslySetInnerHTML={{ __html: guideInfo.contents }}
        />
      </Container>

      {/* 확인 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={() => history.go(-1)}
      />
    </main>
  );
});
