/* eslint-disable no-restricted-syntax */
/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';

// Custom Component
import { CustomModal } from '@components';

// API
import { signIn } from '@api/auth/openAuth';

import PopupComponent from './Popup';
import { getPopup } from '../../api/auth/openAuth';

// 로그인 초기 객체
const initialLoginInput = {
  id: '',
  pw: '',
};

// ===================================================================
// [ 로그인 ]
// ===================================================================
export default React.memo(function SignIn(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  // 로그인
  const [loginInput, setLoginInput] = useState(initialLoginInput);
  const loginInputRefs = useRef([]);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // 팝업
  const [popupDataArray, setPopupDataArray] = useState([]);

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // 초기 렌더링 시 모달 열기
  useEffect(() => {
    const res = getPopup();
    res
      .then(data => {
        setPopupDataArray(data.data.data);
        console.log(data.data.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // ID, PW 입력
  const loginInputHandler = (type, value) => {
    switch (type) {
      case 'ID':
        loginInput.id = value;
        break;
      case 'PW':
        loginInput.pw = value;
        break;
      default:
        break;
    }

    setLoginInput({ ...loginInput });
  };

  // ID, PW 검증
  const isValidLoginInput = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email 정규식

    // ID 검증
    if (!loginInput.id || !emailRegex.test(loginInput.id)) {
      return false;
    }

    // PW 검증
    if (!loginInput.pw) {
      return false;
    }

    return true;
  };

  // ===================================================================
  // [ API ] 로그인
  // ===================================================================
  const submitSignIn = async () => {
    // ID, PW 검증
    const isValid = isValidLoginInput();

    if (!isValid) {
      openModal({ text: '이메일 혹은 비밀번호를 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      signInId: loginInput.id,
      signInPassword: loginInput.pw,
    };

    // Axios
    try {
      const { data } = await signIn(params); // Axios Post Request

      if (data.code === '0000') {
        // 토큰 저장
        localStorage.setItem('access_token', data.data.accessToken);
        localStorage.setItem('refresh_token', data.data.refreshToken);

        // Home 이동
        props.history.push('/sharestatus');
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="sign-in" className="blank">
      <div className="blur-mask" />
      <Container>
        <div className="login">
          <div className="login-body">
            {/* 타이틀 */}
            <h1>HIDC 통합 플랫폼</h1>

            {/* 폼 */}
            <Form>
              {/* 아이디 입력 */}
              <Form.Group>
                <Form.Control
                  className="control-css"
                  type="email"
                  data-label="아이디"
                  name="id"
                  ref={el => (loginInputRefs.current[0] = el)}
                  value={loginInput.id}
                  onChange={e => loginInputHandler('ID', e.target.value)}
                  placeholder="아이디(이메일)"
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      loginInputRefs.current[1].focus();
                    }
                  }}
                  maxLength={30}
                  required
                />
              </Form.Group>

              {/* 비밀번호 입력 */}
              <Form.Group className="mt-3">
                <Form.Control
                  className="control-css"
                  type="password"
                  data-label="비밀번호"
                  name="pw"
                  ref={el => (loginInputRefs.current[1] = el)}
                  value={loginInput.pw}
                  onChange={e => loginInputHandler('PW', e.target.value)}
                  placeholder="비밀번호"
                  maxLength={30}
                  required
                />
              </Form.Group>

              {/* 로그인 */}
              <div className="btn-area column">
                <Button
                  size="lg"
                  disabled={!loginInput.id || !loginInput.pw}
                  onClick={submitSignIn}
                >
                  로그인
                </Button>
              </div>

              {/* 아이디, 비밀번호 찾기 & 회원가입 */}
              <div className="mt-3 flex-center division-box">
                {/* 아이디 찾기 */}
                <Button
                  variant="text"
                  onClick={() => props.history.push('/auth/findId')}
                >
                  아이디 찾기
                </Button>

                {/* 비밀번호 찾기 */}
                <Button
                  variant="text"
                  onClick={() => props.history.push('/auth/findPwd')}
                >
                  비밀번호 찾기
                </Button>

                {/* 회원가입 */}
                <Button
                  variant="text"
                  onClick={() => props.history.push('/auth/signup')}
                >
                  회원가입
                </Button>

                <Button
                  variant="text"
                  onClick={() => props.history.push('/myinfo/oepnGuide')}
                >
                  이용가이드
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Container>

      {/* 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
      />
      {popupDataArray && popupDataArray.length > 0 ? (
        popupDataArray.map(popupData => (
          <PopupComponent
            key={popupData.seq}
            popupIdx={popupData.seq}
            data={popupData}
          />
        ))
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
    </main>
  );
});
