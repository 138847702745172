/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

// Custom Component
import { Header } from '@components';

// API
// import { getNoticeDetail } from '@api/more/more';

// ===================================================================
// [ 공지사항 상세 ]
// ===================================================================
export default React.memo(function Agreement(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const location = useLocation();
  const agreementTyp = location.state?.type;

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(async () => {
    // 약관 출력
    // ...
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="notice-detail">
      {/* 헤더 */}
      <Header title="회원가입 약관" isCloseBtn={false} />

      {/* 컨텐츠 */}
      <Container className="container-between">
        {/* 약관 */}
        <Form className="scroll">
          {/* 약관 - 타이틀 */}
          <div className="detail-titlebox">
            <div className="detail-box">
              <h6>notice.title</h6>
            </div>
          </div>

          {/* 약관 - 본문 */}
          <div
            className="detail-body"
            dangerouslySetInnerHTML={{ __html: `<p>notice.contents</p>` }}
          />
        </Form>

        {/* 확인 */}
        <div className="list-btndiv">
          <Button
            className="lsit-btn"
            variant="outline-primary"
            onClick={() => {
              history.go(-1);
            }}
          >
            확인
          </Button>
        </div>
      </Container>
    </main>
  );
});
