/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Button, Form, Modal } from 'react-bootstrap';
import { WithdrawHeader, CustomSelect } from '@components';
import { useHistory } from 'react-router-dom';
import { getNoticeList } from '@api/more/more';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getAssetDetail,
  getKrwFee,
  withdrawalRequest,
} from '../../api/asset/asset';
import utils from '../../common/Utils';
import CustomModal from '../../components/CustomModal';

const initialPagingParam = {
  pageNo: 1,
  pageSize: 20,
  searchType: '',
  searchTxt: '',
};

// ===================================================================
// [ 보유자산 상세 ]
// ===================================================================
export default React.memo(function AssetsWithdrawDetail(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [krwFee, setKrwFee] = useState(1000);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalText2, setModalText2] = useState('');
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(true); // 출금 성공 여부
  const [accountInfo, setAccountInfo] = useState({});
  const [withdrawReturnMemo, setWithdrawReturnMemo] = useState();
  const location = useLocation();

  const platform =
    location.state && location.state.platform ? location.state.platform : '';

  const changeMoney =
    location.state && location.state.changeMoney
      ? location.state.changeMoney
      : 0;

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCloseModal = async () => {
    closeModal();
    history.push({
      pathname: `/my-assets/detail`,
      state: {
        platform: `KRW`,
      },
    });
  };

  const handleWithdraw = async () => {
    // 출금 처리 로직
    const info = {
      requestAmount: utils.removeComma(changeMoney),
      tax: Math.floor(utils.removeComma(changeMoney) * 0.033),
      realPayment:
        utils.removeComma(changeMoney) -
        Math.floor(utils.removeComma(changeMoney) * 0.033) -
        +krwFee,
    };
    // 출금 성공 시
    const { data } = await withdrawalRequest(info);
    if (data.code === '0000') {
      setWithdrawSuccess(true);
      openModal();
    }

    // 출금 실패 시
    // setWithdrawSuccess(false);
    // openModal();
  };

  let transactionClass = 'transaction-type';

  if (true) {
    transactionClass += ' withdrawal'; // 출금인 경우에 클래스 추가
  }
  //   else if (false === '입금') {
  //     transactionClass += ' deposit'; // 입금인 경우에 클래스 추가
  //   }

  useEffect(() => {
    getAccountInfo();
    getCodeKrwFee();
  }, [location.state]);
  const getAccountInfo = async () => {
    const info = {
      platform,
    };
    const { data } = await getAssetDetail(info);
    setAccountInfo(data.data);
    if (data.data.mbBankAccountNo == null || data.data.mbBankAccountNo === '') {
      setModalShow2(true);
      setModalText2('계좌 인증을 먼저 진행후 출금이 가능 합니다.');
    }
  };

  const getCodeKrwFee = async () => {
    const { data } = await getKrwFee();
    if (data && data.data && data.data[0] && data.data[0].msNm) {
      setKrwFee(data.data[0].msNm);
    } else {
      setKrwFee(1000);
    }
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="assets-withdrawdetail">
      <WithdrawHeader title="출금" isCloseBtn={false} />
      <Container className="container-between">
        <div className="withdraw-box">
          <p className="main-text">출금신청 확인</p>
          <div className="withdrawal-possible">
            <p className="title">계좌</p>
            <p className="amount">
              {accountInfo.mbBankName} {accountInfo.mbBankAccountNo}
            </p>
          </div>
          <div className="withdrawal-possible">
            <p className="title">예금주</p>
            <p className="amount">{accountInfo.mbBankOwner}</p>
          </div>
          <div className="withdrawal-possible">
            <p className="title">출금금액</p>
            <p className="amount">
              {utils.removeComma(changeMoney) > krwFee
                ? utils.numberComma(
                    utils.removeComma(changeMoney) -
                      Math.floor(utils.removeComma(changeMoney) * 0.033) -
                      +krwFee,
                  )
                : 0}{' '}
              {platform}
            </p>
          </div>
          <div className="withdrawal-possible">
            <p className="title">수수료</p>
            <p className="amount">
              {utils.numberComma(krwFee)} {platform}
            </p>
          </div>
          <div className="withdrawal-possible">
            <p className="title">세금</p>
            <p className="amount">
              {utils.numberComma(
                Math.floor(utils.removeComma(changeMoney) * 0.033),
              )}{' '}
              {platform}
            </p>
          </div>
          <div className="withdrawal-possible">
            <p className="title">합계(수수료포함)</p>
            <p className="amount">
              {changeMoney} {platform}
            </p>
          </div>
        </div>

        <div className="bottom-btn">
          <Button onClick={history.goBack}>이전</Button>
          <Button onClick={handleWithdraw}>출금신청</Button>
          <Modal
            show={modalIsOpen}
            onHide={handleCloseModal}
            className="withdrawmodal"
            backdrop="static" // 배경 클릭시 반응 없음
          >
            <Modal.Body>
              {withdrawSuccess ? (
                <div>
                  입력하신 계좌로 출금신청 완료하였습니다.
                  <br />
                  입금까지 영업일 기준 3~4일 소요됩니다.
                </div>
              ) : (
                <div>{withdrawReturnMemo}</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                확인
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
      <CustomModal
        contentText={modalText2}
        headerDisplay={false}
        display={modalShow2}
        onHide={() => history.go(-1)}
      />
    </main>
  );
});
