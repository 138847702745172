/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import { applyNode } from '@api/node/node';

// ===================================================================
// [ 아이템 증명 신청 (2) - 아이템 신청 ]
// ===================================================================
export default React.memo(function AddApplication(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 아이템 증명 양식
  const location = useLocation();
  const platform = location.state?.platform || '';
  const platformHan = location.state?.platformHan || '';

  // 노드 신청 입력값
  const [applyId, setApplyId] = useState(''); // 노드 아이디
  const [applyName, setApplyName] = useState(''); // 신청 이름
  const [applyPhoneNo, setApplyPhoneNo] = useState(''); // 전화번호
  const [nodeApplyAmount, setNodeApplyAmount] = useState(0); // 노드 수량
  const [raceApplyAmount, setRaceApplyAmount] = useState(0); // 경주마 수량
  const [companyName, setCompanyName] = useState(''); // 회사명
  const [memo, setMemo] = useState(''); // 신청 내용

  // 첨부파일
  const fileRef = useRef();
  const [applyFileList, setApplyFileList] = useState([]);

  // 중복 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text, type = '', data = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
    setModalData(data);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 아이디 입력
  const applyIdInputHandler = value => {
    setApplyId(value.trim());
  };

  // 신청 이름 입력
  const applyNameInputHandler = value => {
    setApplyName(value);
  };

  // 전화번호
  const applyPhoneNoInputHandler = value => {
    setApplyPhoneNo(value.replace(/\D/g, ''));
  };

  // 노드 수량
  const nodeAmountInputHandler = value => {
    setNodeApplyAmount(Number(value.replace(/\D/g, '')));
  };

  // 경주마 수량
  const raceAmountInputHandler = value => {
    setRaceApplyAmount(Number(value.replace(/\D/g, '')));
  };

  // 신청 내용
  const memoInputHandler = value => {
    setMemo(value);
  };

  // 파일 첨부
  const addFileHandler = e => {
    const value = e.target.files[0];
    setApplyFileList([value]);
    e.target.value = null;
  };

  // 첨부 파일 삭제
  const removeFile = idx => {
    const filteredList = applyFileList.filter((file, index) => index !== idx);
    setApplyFileList([...filteredList]);

    closeModal();
  };

  // 뒤로가기
  const moveBack = () => {
    history.go(-1);
  };

  // 페이지 이동
  const movePage = pathname => {
    history.push({ pathname });
  };

  // 입력값 검증
  const isValidForm = () => {
    const resultMap = {
      errorMsg: '',
      isValid: false,
    };

    // 아이디 입력
    if (!applyId || !/^[a-zA-Z0-9]{1,30}$/.test(applyId)) {
      resultMap.errorMsg = '신청 아이디를 확인해주세요.';
    }
    // 신청 이름 입력
    else if (!applyName) {
      resultMap.errorMsg = '신청 이름을 확인해주세요.';
    }
    // 휴대전화 번호 입력
    else if (!applyPhoneNo || !/^010\d{7,8}$/.test(applyPhoneNo)) {
      resultMap.errorMsg = '휴대전화 번호를 확인해주세요.';
    }
    // 신청 수량 ( 노드 )
    else if (!nodeApplyAmount && nodeApplyAmount !== 0) {
      resultMap.errorMsg = '신청 노드 수량을 확인해주세요.';
    }
    // 신청 수량 ( 경주마 )
    else if (!raceApplyAmount && raceApplyAmount !== 0) {
      resultMap.errorMsg = '신청 경주마 수량을 확인해주세요.';
    }
    // 신청 수량 ( 노드 + 경주마 )
    else if (nodeApplyAmount < 1 && raceApplyAmount < 1) {
      resultMap.errorMsg = '신청 수량을 확인해주세요.';
    }
    // 플랫폼 정보
    else if (!platform) {
      resultMap.errorMsg = '선택한 플랫폼 정보를 확인해주세요.';
    }
    // 신청 내용
    else if (!memo || memo.length > 1000) {
      resultMap.errorMsg = '신청 내용을 확인해주세요.';
    }
    // 첨부 파일
    else if (applyFileList.length < 1) {
      resultMap.errorMsg = '첨부 파일을 확인해주세요.';
    }
    // 검증 성공
    else {
      resultMap.isValid = true;
    }

    return resultMap;
  };

  // ===================================================================
  // [ API ] 노드 신청
  // ===================================================================
  const applyNewNode = async () => {
    // 중복 방지
    if (isSentRequest) {
      openModal({ text: '진행중인 요청입니다.' });
      return;
    }

    // Input 검증
    const validationResult = isValidForm();

    // 에러 메세지 출력
    if (!validationResult.isValid) {
      openModal({ text: validationResult.errorMsg });
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON append
    formData.append('applyId', applyId);
    formData.append('applyName', applyName);
    formData.append('applyPhoneNo', applyPhoneNo);
    formData.append('nodeApplyAmount', nodeApplyAmount || 0);
    formData.append('raceApplyAmount', raceApplyAmount || 0);
    formData.append('companyName', companyName);
    formData.append('memo', memo);

    // File append
    applyFileList.forEach(item => {
      formData.append('files', item);
    });

    // 중복 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await applyNode(formData);

      // 성공
      if (data.code === '0000') {
        openModal({
          text: '본인 계정 구매 아이템 승인신청이 완료되었습니다.',
          type: 'complete',
        });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsSentRequest(false);

      const { code, message } = error.data || { code: '', message: '' };

      if (code === '1310') {
        openModal({ text: message, type: 'invalid' });
      }
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================

  useEffect(() => {
    // 접근 유효성 확인
    if (!platform) {
      openModal({ text: '잘못된 접근입니다.', type: 'denied' });
    }
    // 정상 접근 > 플랫폼 정보 갱신
    else {
      setCompanyName(platform);
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="addapplication">
      {/* 헤더 */}
      <Header title={`${platformHan} 승인신청`} />

      {/* 컨텐츠 */}
      <Container className="container-between">
        {/* 폼 */}
        <Form className="scroll">
          {/* 신청 아이디 */}
          <Form.Group className="mt-4">
            {/* 라벨 */}
            <Form.Label>신청 아이디</Form.Label>

            {/* 입력 */}
            <Form.Control
              type="text"
              placeholder="신청 아이디 입력"
              value={applyId}
              onChange={e => applyIdInputHandler(e.target.value)}
              maxLength={30}
            />

            {/* 도움말 */}
            <Form.Label className="under-lb">
              해당 마케팅에 사용했던 아이디를 입력하세요.
            </Form.Label>
          </Form.Group>

          {/* 신청 이름 */}
          <Form.Group className="mt-4">
            {/* 라벨 */}
            <Form.Label>신청 이름</Form.Label>

            {/* 입력 */}
            <Form.Control
              type="text"
              placeholder="신청 이름 입력"
              value={applyName}
              onChange={e => applyNameInputHandler(e.target.value)}
              maxLength={30}
            />

            {/* 도움말 */}
            <Form.Label className="under-lb">
              해당 마케팅에 사용했던 이름을 입력하세요.
            </Form.Label>
          </Form.Group>

          {/* 휴대전화 번호 */}
          <Form.Group className="mt-4">
            {/* 라벨 */}
            <Form.Label>{`${platformHan} 휴대전화 번호`}</Form.Label>

            {/* 입력 */}
            <Form.Control
              type="text"
              placeholder="-없이 숫자만 입력"
              value={applyPhoneNo}
              onChange={e => applyPhoneNoInputHandler(e.target.value)}
              maxLength={11}
            />

            {/* 도움말 */}
            <Form.Label className="under-lb">{`${platformHan} 연락처를 입력하세요.`}</Form.Label>
          </Form.Group>

          {/* 신청 노드 수량 */}
          <Form.Group className="mt-4">
            {/* 라벨 */}
            <Form.Label>신청 노드 수량</Form.Label>

            {/* 입력 */}
            <Form.Control
              type="text"
              placeholder="0"
              value={nodeApplyAmount}
              onChange={e => nodeAmountInputHandler(e.target.value)}
              onBlur={!nodeApplyAmount ? () => setNodeApplyAmount(0) : null}
              max={999999}
            />
          </Form.Group>

          {/* 신청 경주마 수량 */}
          <Form.Group className="mt-4">
            {/* 라벨 */}
            <Form.Label>신청 경주마 수량</Form.Label>

            {/* 입력 */}
            <Form.Control
              type="text"
              placeholder="0"
              value={raceApplyAmount}
              onChange={e => raceAmountInputHandler(e.target.value)}
              onBlur={!raceApplyAmount ? () => setRaceApplyAmount(0) : null}
              max={999999}
            />
          </Form.Group>

          {/* 신청 내용 */}
          <Form.Group className="mt-4">
            <div className="text-area">
              {/* 라벨 */}
              <Form.Label>신청내용</Form.Label>

              {/* 입력 */}
              <textarea
                placeholder="신청내용 입력"
                className="textarea-fix"
                value={memo}
                onChange={e => memoInputHandler(e.target.value)}
                maxLength={1000}
              />

              {/* 도움말 */}
              <Form.Label className="under-lb end-lb">{`${memo.length}/1,000자`}</Form.Label>
            </div>
          </Form.Group>

          {/* 첨부 파일 */}
          <div className="img-btn-box">
            <div className="title-box">
              {/* 라벨 */}
              <p className="title">첨부파일</p>

              {/* 도움말 */}
              <p className="title">
                ※ 500MB 이하의 jpg, jpeg, png 파일 업로드 가능합니다.
              </p>
            </div>

            {/* Input */}
            <Form.Control
              ref={fileRef}
              onChange={addFileHandler}
              type="file"
              accept="image/jpg, image/jpeg, image/png"
              style={{ display: 'none' }}
            />

            {/* 버튼 */}
            <Button
              variant="outline-primary"
              onClick={() => fileRef.current.click()}
            >
              선택
            </Button>

            {/* 파일 리스트 */}
            <div className="img-btn-box">
              <div className="img-line">
                {applyFileList.length > 0 &&
                  applyFileList.map((item, idx) => {
                    return (
                      <p
                        key={`apply-file-list-${idx + 1}`}
                        className="img-text"
                      >
                        {item.name}
                        <span
                          className="cancle-btn"
                          onClick={() =>
                            openModal({
                              text: '이미지를 삭제하시겠습니까?',
                              type: 'delete-image',
                              data: idx,
                            })
                          }
                        />
                      </p>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* 신청 */}
          <div className="btn-area fix-bottom">
            <Button variant="outline-primary" onClick={applyNewNode}>
              신청
            </Button>
          </div>
        </Form>
      </Container>

      {/* 확인 모달 */}
      <CustomModal
        messageOnly={modalType !== 'delete-image'}
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
        onConfirm={
          modalType === 'denied'
            ? moveBack
            : modalType === 'invalid'
            ? () => movePage('/status/authcenter')
            : modalType === 'complete'
            ? () => movePage('/sharestatus')
            : modalType === 'delete-image'
            ? () => removeFile(modalData)
            : closeModal
        }
      />
    </main>
  );
});
