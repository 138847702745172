/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line prettier/prettier
import React, { useRef, useEffect, useState } from 'react';
import { Container, Form, Button, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { CustomSelect, CustomModal, Header } from '@components';

// API
import { getBankList, applyBank, getBank } from '@api/bank/bank';

// KYC 초기 객체
const initialAccountInfo = {
  type: '',
  mbFullName: '',
  mbJuminNo1: '',
  mbJuminNo2: '',
};

// 거래 은행
const bankDumy = [
  {
    value: '004',
    label: '국민은행',
  },
];

// ===================================================================
// [ KYC 등록 ]
// ===================================================================
export default React.memo(function IdenCheck(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 거래 은행 정보
  const [bankType, setBankType] = useState(bankDumy[0]);
  const [BankList, setBankList] = useState(bankDumy);
  const [accountInfo, setAccountInfo] = useState(initialAccountInfo);

  // 첨부파일
  const accountFileRef = useRef('');
  const [accountFile, setAccountFile] = useState('');

  // 약관
  const [isAgreed, setIsAgreed] = useState(false);

  // 중복 방지
  const [isSentRequest, setIsSentRequest] = useState(true);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // 계좌 번호
  const [accountNumber, setAccountNumber] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 신분증 종류 선택
  const bankTypeHandler = value => {
    setBankType(value);
  };

  // 예금주 입력
  const nameInputHandler = value => {
    accountInfo.mbFullName = value;
    setAccountInfo({ ...accountInfo });
  };

  // 파일 첨부
  const addFileHandler = e => {
    const value = e.target.files[0];
    setAccountFile(value);
    e.target.value = null;
  };

  // 첨부 파일 삭제
  const removeFile = () => {
    setAccountFile('');
  };

  // 약관
  const agreeCheckHandler = value => {
    setIsAgreed(value);
  };

  // Input 검증
  const isValidForm = () => {
    const resultMap = {
      errorMsg: '',
      isValid: false,
    };

    // 예금주 검증
    if (!accountInfo.mbFullName) {
      resultMap.errorMsg = '예금주를 확인해주세요.';
    }

    // 파일 확인
    else if (!accountFile) {
      resultMap.errorMsg = '첨부 파일을 확인해주세요.';
    }

    // 약관 확인
    // else if (!isAgreed) {
    //   resultMap.errorMsg = '약관을 확인해주세요.';
    // }

    // 검증 성공
    else {
      resultMap.isValid = true;
    }

    return resultMap;
  };

  // 계좌 번호
  const accountInputHandler = e => {
    const inputValue = e.target.value;
    const onlyNumbers = /^[0-9\b]+$/;

    // 입력된 값이 숫자인지 확인
    if (inputValue === '' || onlyNumbers.test(inputValue)) {
      // 숫자인 경우 state 업데이트
      setAccountNumber(inputValue);
    }
  };

  // KYC, 지갑 정보 조회
  useEffect(() => {
    getBankInfoList();
    getMyBankStatus();
    return () => {};
  }, []);

  const getMyBankStatus = async () => {
    // Axios
    try {
      const { data } = await getBank();
      // 성공
      if (data.code === '0000') {
        if (data.data && data.data.status && data.data.status === 'REQ') {
          // KYC 데이터 주입
          history.push('/status/authcenter');
        }
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsConfirmed(false);
    }
  };

  const getBankInfoList = async () => {
    const { data } = await getBankList();
    const transformedData = data.data.map(item => {
      return {
        value: item.msCd,
        label: item.msNm,
      };
    });

    setBankList(transformedData);
  };

  const saveBankInfo = async () => {
    const formData = new FormData();
    const params = {
      bankCode: bankType.value,
      bankName: bankType.label,
      bankOwner: accountInfo.mbFullName.replace(/^\s+|\s+$/g, ''),
      address: accountNumber,
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    // File append
    formData.append('file', accountFile);

    const { data } = await applyBank(formData);
    history.push('/account/complete');
  };
  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="account-check">
      {/* 헤더 */}
      <Header title="은행 계좌 인증" />

      {/* 컨텐츠 */}
      <Container className="container-between ">
        <Form className="scroll">
          {/* 안내 사항 */}
          <div>
            <div className="title-agree">
              <h4>은행 계좌 인증</h4>
              <p>실명인증을 위해 보유하고 있는 계좌정보를 입력해주세요.</p>
            </div>

            {/* 입력 FORM */}
            <div>
              {/* 거래은행 종류 - 드랍다운 */}
              <Form.Group>
                <Form.Label>거래은행</Form.Label>
                <CustomSelect
                  options={BankList}
                  selectedValue={bankType}
                  onSelect={bankTypeHandler}
                  placeholder="선택"
                  className="mt-2"
                />
              </Form.Group>

              {/* 계좌번호 */}
              <Form.Group className="mt-4">
                <Form.Label>계좌번호</Form.Label>
                <InputGroup className="mt-2">
                  <Form.Control
                    type="text"
                    placeholder="숫자로만 입력"
                    onChange={accountInputHandler}
                    value={accountNumber}
                  />
                </InputGroup>
              </Form.Group>

              {/* 예금주 */}
              <Form.Group className="mt-4">
                <Form.Label>예금주</Form.Label>
                <InputGroup className="mt-2">
                  <Form.Control
                    type="text"
                    placeholder="계좌에 등록된 예금주 입력"
                    onChange={e => nameInputHandler(e.target.value)}
                    maxLength={10}
                    value={accountInfo.mbFullName}
                  />
                </InputGroup>
              </Form.Group>
            </div>

            {/* 첨부파일 */}
            <div className="bkselect-div">
              {/* 라벨 */}
              <p className="title">통장사본 첨부(2MB 이하)</p>
              <p className="info">
                이름과 계좌번호가 명확하게 표시된 은행 계좌 사본 이미지를 업로드
                해주세요.
              </p>

              {/* Input */}
              <Form.Control
                ref={accountFileRef}
                onChange={addFileHandler}
                type="file"
                accept="image/*, .pdf"
                style={{ display: 'none' }}
              />

              {/* 첨부파일 내역 */}
              {accountFile && (
                <div className="img-btn-box">
                  <div className="img-line">
                    <p className="img-text">
                      {accountFile.name}
                      <span className="cancle-btn" onClick={removeFile} />
                    </p>
                  </div>
                </div>
              )}

              {/* 파일 선택 버튼 */}
              <Button
                variant="outline-primary"
                onClick={() => accountFileRef.current.click()}
              >
                선택
              </Button>
            </div>
          </div>

          <div>
            {/* 약관 */}
            {/* <Form.Group className="check-div"> */}
            {/*  <Form.Check */}
            {/*    type="checkbox" */}
            {/*    label="(필수) 개인정보의 수집 및 이용 동의" */}
            {/*    id="agree1" */}
            {/*    value="agree1" */}
            {/*    checked={isAgreed} */}
            {/*    onChange={e => agreeCheckHandler(e.target.checked)} */}
            {/*  /> */}
            {/*  <Button className="btn-next" /> */}
            {/* </Form.Group> */}

            {/* 등록 */}
            <div className="btn-area">
              <Button
                className="w-100"
                disabled={
                  // !isAgreed ||
                  !accountInfo.mbFullName || !bankType || !accountFile
                }
                onClick={() => saveBankInfo()}
              >
                신청
              </Button>
            </div>
          </div>
        </Form>
      </Container>

      {/* 메세지 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
      />
    </main>
  );
});
