/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Button, Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { MainHeader, Navi, CustomModal } from '@components';

// API
import { memberInfo } from '@api/more/more';

// 회원 내정보 초기 객체
const initialMbInfo = {
  mbIdx: '',
  mbId: '',
  mbFullName: '',
  mbPhoneNo: '',
  regDate: '',
  mbCertifyKyc: '',
};

// ===================================================================
// [ 내 정보 ]
// ===================================================================
export default React.memo(function MyInfo(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const [mbInfo, setMbInfo] = useState(initialMbInfo);
  const [isKycComplete, setIsKycComplete] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text, type = '', data = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
    setModalData(data);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  const moveBack = () => {
    history.go(-1);
  };

  const movePage = pathname => {
    history.push({ pathname });
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  const AuthCenterClick = () => {
    history.push('/status/authcenter');
  };

  // 탭 변경
  const tabChangeHandler = key => {
    if (key === '인증센터') AuthCenterClick();
  };

  // 핸드폰 번호 형식 변경
  const formatPhoneNumber = phoneNumber => {
    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  // 로그아웃
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    history.push('/');
  };

  // ===================================================================
  // [ useEffect ] 회원 정보 가져오기
  // ===================================================================
  useEffect(async () => {
    const { data } = await memberInfo(); // Axios Post Request

    if (data.code === '0000') {
      if (data.data.mbCertifyKyc === 'Y') setIsKycComplete(true);
      if (data.data.mbCertifyKyc === 'N') setIsKycComplete(false);
      const formattedData = {
        ...data.data,
        mbPhoneNo: formatPhoneNumber(data.data.mbPhoneNo),
      };
      setMbInfo(formattedData);
    } else {
      console.error(error);
    }
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="myinfo">
      {/* 헤더 */}
      <MainHeader />

      {/* 컨텐츠 */}
      <Container className="container-custom">
        {/* 탭 - 헤더 */}
        <div id="Tab-css">
          <Tabs
            defaultActiveKey="내정보"
            justify
            onSelect={activeKey => tabChangeHandler(activeKey)}
          >
            <Tab eventKey="내정보" title="내정보" />
            <Tab eventKey="인증센터" title="인증센터" />
          </Tabs>
        </div>

        {/* 탭 - 컨텐츠 */}
        <div className="info-body">
          {/* 내정보 (1) */}
          <div className="title-box">
            <div className="title-textbox">
              {/** 미확인 undif , kyc 인증완료 check */}
              {!isKycComplete && <p className="label undif">미확인</p>}
              {isKycComplete && <p className="label check">KYC 인증완료</p>}
              <p className="id-text">{mbInfo.mbId}</p>
            </div>
            <Button
              className="edit-btn"
              onClick={() => {
                history.push({ pathname: `/myinfo/edit` });
              }}
            >
              정보 수정
            </Button>
          </div>

          {/* 내정보 (2) */}
          <div className="info">
            <div className="info-line">
              <div className="info-flex">
                <p className="info-title">이름</p>
                <p className="info-sub">{mbInfo.mbFullName}</p>
              </div>
              <div className="info-flex">
                <p className="info-title">휴대전화 번호</p>
                <p className="info-sub">{mbInfo.mbPhoneNo}</p>
              </div>
              <div className="info-flex">
                <p className="info-title">가입일</p>
                <p className="info-sub">{mbInfo.regDate}</p>
              </div>
            </div>
          </div>

          {/* 공지사항 */}
          <div className="link-box">
            <div
              className="link-flex"
              onClick={() => {
                history.push({ pathname: `/myinfo/notice` });
              }}
            >
              <p>공지사항</p>
              <div className="link-img" />
            </div>

            {/* 서비스 이용약관 */}
            <div
              className="link-flex"
              onClick={() => {
                history.push({
                  pathname: `/myinfo/clause`,
                  search: `?type=useOfServices`,
                });
              }}
            >
              <p>서비스 이용약관</p>
              <div className="link-img" />
            </div>

            {/* 개인정보 처리방침 */}
            <div
              className="link-flex"
              onClick={() => {
                history.push({
                  pathname: `/myinfo/clause`,
                  search: `?type=privacyStatement`,
                });
              }}
            >
              <p>개인정보 처리방침</p>
              <div className="link-img" />
            </div>

            {/* 준법서약 확인 동의서 */}
            <div
              className="link-flex"
              onClick={() => {
                history.push({
                  pathname: `/myinfo/clause`,
                  search: `?type=lawCertificate`,
                });
              }}
            >
              <p>준법 서약 확인 동의서</p>
              <div className="link-img" />
            </div>

            {/* 이용가이드 */}
            <div
              className="link-flex"
              onClick={() => {
                history.push({
                  pathname: `/myinfo/guide`,
                });
              }}
            >
              <p>이용가이드</p>
              <div className="link-img" />
            </div>

            {/* 로그아웃 */}
            <div className="link-flex" onClick={() => logout()}>
              <p>로그아웃</p>
            </div>
          </div>
        </div>
      </Container>

      {/* 푸터 */}
      <Navi />

      {/* 확인 모달 */}
      <CustomModal
        messageOnly={modalType !== 'delete-image'}
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
        onConfirm={
          modalType === 'denied'
            ? moveBack
            : modalType === 'invalid'
            ? () => movePage('/myinfo')
            : modalType === 'complete'
            ? () => movePage('/myinfo')
            : closeModal
        }
      />
    </main>
  );
});
