/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { MainHeader, Navi, CustomModal } from '@components';

// API
import { getWalletAddress } from '@api/wallet/wallet';

// 지갑 초기 정보
const initialWalletInfo = {
  seq: '',
  status: '',
  statusHan: '',
  address: '',
  qrCode: '',
  memo: '',
};

// 지갑 초기 스타일 & 라벨 & 이벤트
const initialWalletOptions = {
  label: '',
  style: '',
  fn: null,
};

// ===================================================================
// [ 지갑 현황 ]
// ===================================================================
export default React.memo(function MyWallet(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 지갑 정보
  const [walletInfo, setWalletInfo] = useState(initialWalletInfo);
  const [walletOptions, setWalletOptions] = useState(initialWalletOptions);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 'granderby' 새 페이지 열기
  const openGranderby = () => {
    window.open('https://hidc.granderby.io/');
  };

  // 지갑 재등록 이동
  const moveReAddWallet = () => {
    history.push({ pathname: `/wallet/readdwallet` });
  };

  // 지갑 상태별 스타일 변환 ( null = 미등록, complete = 등록완료, cancle = 반려, stay 승인대기 )
  const convertOptions = status => {
    switch (status) {
      // 승인대기
      case 'REQ':
        walletOptions.label = '승인대기';
        walletOptions.style = 'stay';
        walletOptions.fn = null;
        break;
      // 승인
      case 'OK':
        walletOptions.label = '승인';
        walletOptions.style = 'complete';
        walletOptions.fn = null;
        break;
      // 반려
      case 'REJ':
        walletOptions.label = '반려';
        walletOptions.style = 'cancle';
        walletOptions.fn = null;
        break;
      // 미등록
      default:
        walletOptions.label = '미등록';
        walletOptions.style = '';
        walletOptions.fn = null;
        break;
    }

    setWalletOptions({ ...walletOptions });
  };

  // 클립보드 복사
  const copyAddress = () => {
    navigator.clipboard
      .writeText(walletInfo.address)
      .then(() => {
        openModal({ text: '지갑주소가 복사되었습니다.' });
      })
      .catch(() => {
        openModal({ text: '다시 복사를 시도해주세요.' });
      });
  };

  // ===================================================================
  // [ API ] 지갑 정보 가져오기
  // ===================================================================
  const getWalletInfo = async () => {
    // JSON 파라미터
    const params = {
      qrWidth: 150,
      qrHeight: 150,
    };

    // Axios
    try {
      const { data } = await getWalletAddress(params);

      // 성공
      if (data.code === '0000') {
        setWalletInfo({
          seq: data.data?.walletApplyDto.seq,
          address: data.data?.walletApplyDto.address,
          status: data.data?.walletApplyDto.status,
          statusHan: data.data?.walletApplyDto.statusHan,
          qrCode: `data:image/png;base64, ${data.data?.qrByteBase64}`,
          memo: data.data?.walletApplyDto.admMemo,
        });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getWalletInfo();

    return () => {};
  }, []);

  useEffect(() => {
    convertOptions(walletInfo.status);

    return () => {};
  }, [walletInfo]);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="mywallet">
      {/* 헤더 */}
      <MainHeader />

      {/* 컨텐츠 (1) - 미등록 */}
      {!walletInfo.status && (
        <Container className="container-custom">
          <Form className="scroll">
            <div className="wallet-box bd-none">
              <h6 className="wallet-title">내 지갑 주소</h6>
              <div className="qr-box">
                {/* 지갑 주소 - QR 코드 */}
                <div
                  className="wallet-add"
                  onClick={() => {
                    history.push({ pathname: `/wallet/addwallet` });
                  }}
                >
                  <i className="material-icons">add</i>
                </div>
                <p className="wallet-text">
                  버튼을 클릭해 회원님의
                  <br />
                  Granderby 지갑 주소를 입력해주세요.
                </p>
              </div>
            </div>

            {/* Granderby 자산연결 */}
            <div className="assets-box" onClick={openGranderby}>
              <div className="it-line">
                <div className="assets-imgbox">
                  <div className="img" />
                </div>
                <p className="assets-text">Granderby 자산 연결</p>
              </div>
              <i className="material-icons">arrow_forward_ios</i>
            </div>

            {/* 유의사항 */}
            <div className="caution-box">
              <p className="caution-title">유의사항</p>
              <div className="caution-pd">
                <p className="caution-text pd-bt">
                  Granderby 지갑 주소만 추가 가능합니다.
                </p>
                <p className="caution-text pd-bt">
                  가입하신 아이디와 동일한 아이디의 지갑 추가 시 승인이 원활하게
                  이루어집니다.
                </p>
                <p className="caution-text">
                  지갑 생성이 필요할 경우 아래의 링크를 통해 생성 바랍니다.
                </p>
              </div>
            </div>
          </Form>
        </Container>
      )}

      {/* 컨텐츠 (2) - 승인대기 or 반려 */}
      {(walletInfo.status === 'REQ' || walletInfo.status === 'REJ') && (
        <Container className="container-custom">
          <Form className="scroll">
            <div className="wallet-div">
              <h6 className="wallet-title">내 지갑 주소</h6>
              <div className="wallet-box">
                {/* 상태 */}
                <p className={`label ${walletOptions.style}`}>
                  {walletOptions.label}
                </p>

                {/* 지갑주소 */}
                <div
                  className={`address-flex ${
                    walletInfo.status === 'REJ' && 'bottom-line'
                  }`}
                >
                  {/* 라벨 */}
                  <p className="address-text">지갑주소</p>

                  {/* 주소 */}
                  <p className="address">{walletInfo.address}</p>
                </div>

                {/* 상세내용 - '반려' Only */}
                {walletInfo.status === 'REJ' && (
                  <>
                    <div className="reasonbox">
                      {/* 라벨 */}
                      <p className="reason-title">반려 사유</p>

                      {/* 반려 사유 */}
                      <p className="reason-body">{walletInfo.memo}</p>
                    </div>

                    {/* 재등록 */}
                    <Button
                      variant="outline-primary mt-3"
                      className="wb-btn"
                      onClick={moveReAddWallet}
                    >
                      재등록
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Form>
        </Container>
      )}

      {/* 컨텐츠 (3) - 승인 */}
      {walletInfo.status === 'OK' && (
        <Container className="container-custom">
          <Form className="scroll">
            <div className="wallet-box bd-none">
              <h6 className="wallet-title">내 지갑 주소</h6>
              <div className="qr-box">
                {/* QR Code */}
                <img src={walletInfo.qrCode} alt="QR Code" />
              </div>
              {/* 지갑주소 */}
              <div className="text-center">
                <p className="wallet-text">{walletInfo.address}</p>
              </div>
              <Button className="address-btn" onClick={copyAddress}>
                주소 복사
              </Button>
              {/* 재등록 */}
              <Button
                variant="outline-primary mt-3"
                className="wb-btn"
                onClick={moveReAddWallet}
              >
                재등록
              </Button>
            </div>
          </Form>
        </Container>
      )}

      {/* 푸터 */}
      <Navi />

      {/* 메세지 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
      />
    </main>
  );
});
