/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import { applyWallet } from '@api/wallet/wallet';

// ===================================================================
// [ 지갑 재등록 ]
// ===================================================================
export default React.memo(function AddWallet(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 지갑 주소
  const [walletAddress, setWalletAddress] = useState('');

  // 첨부파일
  const walletFileRef = useRef('');
  const [walletFile, setWalletFile] = useState('');

  // 중복 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 메세지 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 메세지 모달 출력
  const openModal = ({ text, type = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
  };

  // 메세지 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 지갑주소 입력
  const walletAddressInputHandler = value => {
    setWalletAddress(value);
  };

  // 파일 첨부
  const addFileHandler = e => {
    const value = e.target.files[0];
    setWalletFile(value);
    e.target.value = null;
  };

  // 첨부 파일 삭제
  const removeFile = () => {
    setWalletFile('');
  };

  // ===================================================================
  // [ API ] 지갑 등록
  // ===================================================================
  const addNewWallet = async () => {
    // 중복 방지
    if (isSentRequest) {
      openModal({ text: '진행중인 요청입니다.' });
      return;
    }

    // 지갑주소 검증
    if (!walletAddress) {
      openModal({ text: '지갑주소를 입력해주세요.' });
      return;
    }

    // 첨부 파일 검증
    if (!walletFile) {
      openModal({ text: '첨부 파일을 확인해주세요.' });
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON append
    formData.append('address', walletAddress);

    // File append
    formData.append('files', walletFile);

    // 중복 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await applyWallet(formData);

      // 성공
      if (data.code === '0000') {
        openModal({
          text: `지갑 주소 추가 완료되었습니다. 
                승인완료까지 영업일 기준 3~4일 소요됩니다.`,
          type: 'add-wallet',
        });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="readdwallet">
      {/* 헤더 */}
      <Header title="지갑 재등록" />

      {/* 컨텐츠 */}
      <Container className="container-between">
        <div className="addwalletbox">
          <div className="title-agree">
            <h4>지갑 확인</h4>
            <div className="iden-box">
              <div className="img-box" />
              <p>
                Granderby 지갑 주소와 이메일 주소를 정확히 확인 가능한 파일로
                등록해 주세요.
              </p>
            </div>
          </div>
          {/* 지갑 주소 입력 */}
          <Form.Group>
            <Form.Label>지갑주소</Form.Label>
            <Form.Control
              type="text"
              placeholder="지갑주소 입력"
              value={walletAddress}
              onChange={e => walletAddressInputHandler(e.target.value)}
            />
          </Form.Group>

          {/* 첨부 파일 */}
          <div className="img-btn-box">
            <div className="title-box">
              {/* 라벨 */}
              <p className="title">첨부파일</p>

              {/* Input */}
              <Form.Control
                ref={walletFileRef}
                onChange={addFileHandler}
                type="file"
                accept="image/*, .pdf"
                style={{ display: 'none' }}
              />

              {/* 안내문구 */}
              <p className="title">
                ※ 500MB 이하의 jpg, jpeg, png 파일
                <br />
                1개 업로드 가능합니다.
              </p>
            </div>

            {/* 버튼 */}
            <Button
              variant="outline-primary"
              onClick={() => walletFileRef.current.click()}
            >
              선택
            </Button>

            {/* 파일 목록 */}
            {walletFile && (
              <div className="img-line">
                <p className="img-text">
                  {walletFile.name}
                  <span className="cancle-btn" onClick={removeFile} />
                </p>
              </div>
            )}
          </div>
        </div>

        {/* 지갑등록 */}
        <div className="btn-area fix-bottom">
          <Button
            disabled={!walletAddress || !walletFile}
            onClick={addNewWallet}
          >
            등록
          </Button>
        </div>
      </Container>

      {/* 메세지 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
        onConfirm={
          modalType === 'add-wallet'
            ? () => history.push('/wallet')
            : closeModal
        }
      />
    </main>
  );
});
