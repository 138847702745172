/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// Custom Component
import { Header, CustomModal } from '@components';

// ===================================================================
// [ 비밀번호 찾기 결과 페이지 ]
// ===================================================================
export default React.memo(function FindId(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 계정 정보
  const location = useLocation();
  const { phone = '' } = location.state;

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    if (!phone) {
      openModal({ text: '잘못된 접근입니다.' });
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="pwdrestlink">
      {/* 헤더 */}
      <Header />

      {/* 컨텐츠 */}
      <Container className="container-between">
        {/* 처리 결과 */}
        <Form className="scroll mt-4">
          <div className="mail-div">
            <div className="mail-img" />

            {/* 전화번호 */}
            <p className="phone">
              {phone &&
                phone.replace(/(\d{3})(\d{1})\d{3}(\d{4})/, '$1-$2***-$3')}
            </p>

            {/* 내역 */}
            <p className="pwd-text">
              비밀번호 재설정 링크를 보내드렸습니다.
              <br />
              링크를 눌러 비밀번호를 재설정하세요.
            </p>
          </div>
        </Form>
        <div className="btn-area fix-bottom">
          <Button
            size="lg"
            onClick={() => {
              history.push({ pathname: `/` });
            }}
          >
            확인
          </Button>
        </div>
      </Container>

      {/* 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={() => history.go(-1)}
      />
    </main>
  );
});
