/* eslint-disable no-constant-condition */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import { AssetsHeader, CustomSelect } from '@components';
import { useHistory } from 'react-router-dom';
import { getNoticeList } from '@api/more/more';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  cancelWithdrawalRequest,
  getAssetDetail,
  transactionHistory,
} from '../../api/asset/asset';
import utils from '../../common/Utils';
import { CustomModal } from '../../components';

const initialPagingParam = {
  pageNo: 1,
  pageSize: 20,
  searchType: '',
  searchTxt: '',
};

const excTypeInfo = {
  DACKRW: '관리자 자산 입금반영',
  DCKRW: '입금',
  WCKRW: '출금',
  DAIHKRW: 'NFT수익 분배입금',
  WAIHKRW: 'NFT수익 분배출금',
  DAIKRW: '노드균등 원화 수익분배 입금',
  WAIKRW: '노드균등 원화 수익분배 출금',
};

const excTypes = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '입금',
    value: 'D',
  },
  {
    label: '출금',
    value: 'W',
  },
];

const excState = {
  REQ: '진행중',
  CAN: '취소완료',
  REJ: '반려완료',
  OK: '처리완료',
};

const initAccount = {
  balance: 0,
  exchangeCan: 0,
  exchangeIng: 0,
  tradeCan: 0,
  tradeIng: 0,
  tradeOnly: 0,
  regDate: '2023-10-17 18:22:47.0',
  binary: 0,
  bnc: 0,
  fileNode: 0,
  edo: 0,
  bnf: 0,
  binaryHan: null,
  bncHan: null,
  fileNodeHan: null,
  edoHan: null,
  bnfHan: null,
};

// ===================================================================
// [ 보유자산 상세 ]
// ===================================================================
export default React.memo(function AssetsDetail(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const [accountInfo, setAccountInfo] = useState(initAccount);
  const [excType, setExcType] = useState(excTypes[0]);
  const [exchangeList, setExchangeList] = useState([]);
  // 메세지 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalText, setModalText] = useState('');
  const [seq, setSeq] = useState('');

  const history = useHistory();
  const location = useLocation();
  const platform =
    location.state && location.state.platform ? location.state.platform : '';

  // 신분증 종류 선택
  const excTypeHandler = value => {
    setExcType(value);
  };

  useEffect(() => {
    getExchangeList();
  }, [excType]);

  useEffect(() => {
    getAccountInfo();
    getExchangeList();
  }, [location.state]);

  let transactionClass = 'transaction-type';
  const getAccountInfo = async () => {
    const info = {
      platform,
    };
    const { data } = await getAssetDetail(info);
    setAccountInfo(data.data);
  };

  const getExchangeList = async () => {
    const info = {
      platform,
      excType: excType.value,
    };
    const { data } = await transactionHistory(info);
    const combinedList = [...data.data.exchangeList, ...data.data.applyList];
    combinedList.sort((a, b) => new Date(b.regDate) - new Date(a.regDate));
    const formattedData = combinedList.map(item => ({
      ...item,
      regDate: item.regDate.replace(
        /(\d{2})(\d{2})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
        '$2.$3.$4  $5:$6:$7',
      ),
    }));

    setExchangeList(formattedData);
  };

  if (true) {
    transactionClass += ' withdrawal'; // 출금인 경우에 클래스 추가
  }
  const closeModal = () => {
    setModalShow(false);
    setModalText('');
    setModalShow2(false);
    setModalShow3(false);
  };

  const openCancelModal = exchangeSeq => {
    setModalText('출금 신청을 취소하시겠습니까?');
    setModalShow(true);
    setSeq(exchangeSeq);
  };

  const modifyApplyInfo = async () => {
    setModalShow(false);

    const { data } = await cancelWithdrawalRequest(seq);
    if (data.code === '0000') {
      getAccountInfo();
      getExchangeList();
      setModalText('취소 처리 되었습니다.');
      setModalShow2(true);
    }
  };

  function pageWithdrawMoveHandle() {
    if (
      accountInfo.mbBankAccountNo == null ||
      accountInfo.mbBankAccountNo === ''
    ) {
      setModalShow3(true);
      setModalText('계좌 인증을 먼저 진행후 출금이 가능 합니다.');
      return;
    }
    history.push({
      pathname: `/my-assets/withdraw`,
      state: {
        platform: `${platform}`,
      },
    });
  }

  function pageBankCertifyPage() {
    history.push({
      pathname: `/status/authcenter`,
      state: {
        platform: `${platform}`,
      },
    });
  }
  // ===================================================================
  // [ return ]

  // ===================================================================
  return (
    <main id="assets-detail">
      <AssetsHeader title={platform} isCloseBtn={false} />
      <Container className="container-between">
        <div className="top-detail">
          <div className="assets-history">
            <p className="title">총 보유자산 내역</p>
            <p className="amount">
              {utils.numberComma(accountInfo.balance ? accountInfo.balance : 0)}{' '}
              {platform}
            </p>
          </div>
          {platform && platform === 'KRW' ? (
            <>
              <div className="withdrawal-possible">
                <p className="title">출금가능</p>
                <p className="amount">
                  {utils.numberComma(accountInfo.exchangeCan)}{' '}
                  <span>{platform}</span>
                </p>
              </div>
              <hr />
              <Button
                onClick={() => {
                  pageWithdrawMoveHandle();
                }}
              >
                출금
              </Button>
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <></>
          )}
        </div>
        <Form className="scroll">
          <div className="transaction-title">
            <p className="title">거래내역</p>
            <CustomSelect
              options={excTypes}
              selectedValue={excType}
              onSelect={excTypeHandler}
              placeholder="전체"
              className=""
            />
          </div>
          {exchangeList && exchangeList.length > 0 ? (
            exchangeList.map(item => (
              <div className="transaction-history">
                <div className="transaction-list">
                  <div className="list">
                    <p className="time">{item.regDate}</p>
                    {item.excType.charAt(0) === 'W' ? (
                      <p className="withdrawal">{item.excTypeHan}</p>
                    ) : item.excType.charAt(0) === 'D' ? (
                      <p className="deposit">{item.excTypeHan}</p>
                    ) : (
                      <p />
                    )}
                  </div>
                  <p className="amount">
                    {utils.numberComma(item.requestAmount)}{' '}
                    <span>{platform}</span>
                  </p>
                </div>
                <div className="status">
                  <p className="title">상태</p>
                  <p>{excState[item.excState]}</p>
                </div>
                {item.excState === 'REQ' ? (
                  <Button onClick={() => openCancelModal(item.seq)}>
                    취소
                  </Button>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
              </div>
            ))
          ) : (
            <div className="transaction-history">
              <div className="transaction-list">
                <div className="container-center">
                  <p className="no-data">검색 결과가 없습니다.</p>
                </div>
              </div>
            </div>
          )}
        </Form>
      </Container>
      <CustomModal
        contentText={modalText}
        messageOnly={false}
        display={modalShow}
        confirmBtnText="확인"
        onHide={() => closeModal()}
        onConfirm={() => modifyApplyInfo()}
      />

      <CustomModal
        contentText={modalText}
        display={modalShow2}
        onHide={() => closeModal()}
      />

      <CustomModal
        contentText={modalText}
        display={modalShow3}
        confirmBtnText="이동"
        onConfirm={() => pageBankCertifyPage()}
      />
    </main>
  );
});
