import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';

function PopupComponent({ data, popupIdx }) {
  const [onClose, setOnClose] = useState(false);
  const [display, setDisplay] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const closeBtn = <Button className="btn-close" onClick={onClose} />;

  useEffect(() => {
    const storedHiddenPopups = JSON.parse(localStorage.getItem('popup_info'));
    let checkDisplay = true;
    if (storedHiddenPopups) {
      const existingPopup = storedHiddenPopups.find(
        item => item.popupIdx === popupIdx,
      );

      if (existingPopup) {
        const beforeDate = new Date(existingPopup.closedAt);
        const nowDate = new Date();

        const timeUntilTomorrow = beforeDate - nowDate;
        if (timeUntilTomorrow > 0) {
          checkDisplay = false;
        }
      }
    }

    setDisplay(checkDisplay);
  }, []);

  const closePopup = () => {
    setDisplay(false);
    if (checked) {
      closeWin();
    }
  };

  const closeWin = () => {
    let storedHiddenPopups = JSON.parse(localStorage.getItem('popup_info'));
    let existingPopup = null;
    if (storedHiddenPopups) {
      existingPopup = storedHiddenPopups.find(
        item => item.popupIdx === popupIdx,
      );
    } else {
      storedHiddenPopups = [];
    }

    const tomorrow = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1,
      0, // 시
      0, // 분
      0, // 초
    );
    if (existingPopup) {
      // 업데이트: 이미 존재하는 경우
      existingPopup.closedAt = tomorrow;
    } else {
      // 추가: 존재하지 않는 경우
      storedHiddenPopups.push({
        popupIdx,
        closedAt: tomorrow,
      });
    }
    // localStorage에 hiddenPopups 정보 저장
    localStorage.setItem('popup_info', JSON.stringify(storedHiddenPopups));
  };

  return (
    <Modal
      size="lg"
      show={display}
      onHide={null}
      autoFocus={false}
      returnFocusAfterClose={false}
    >
      {/* 모달 - 헤더 */}
      <Modal.Header
        style={{
          textAlign: 'left',
          paddingTop: '3px',
          paddingBottom: '3px',
          paddingLeft: '20px',
          paddingRight: '20px',
          color: '#000',
          position: 'fixed',
          width: '100%',
          zIndex: 1000,
          background: '#fff',
          borderBottom: '1px solid #e0e0e0',
        }}
        close={closeBtn}
      >
        <Modal.Title
          style={{
            fontWeight: '600',
            marginBottom: 0,
          }}
        >
          공지사항
        </Modal.Title>
      </Modal.Header>

      {/* 모달 - 바디 */}
      <ModalBody
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '40px',
          paddingBottom: '50px',
          paddingLeft: '3px',
          paddingRight: '3px',
          overflowY: 'auto',
          maxHeight: 'calc(100vh - 90px)',
        }}
      >
        <Modal.Body
          style={{
            margin: '0 auto',
            fontWeight: '500',
            minHeight: '150px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            padding: '1px',
          }}
        >
          <div
            style={{
              textAlign: 'center',
              margin: '0 auto',
              fontSize: '18px',
              wordWrap: 'break-word',
            }}
          >
            {data.title}
          </div>
          <br />
          <div
            dangerouslySetInnerHTML={{ __html: data.contents }}
            style={{ overflowY: 'auto', wordWrap: 'break-word' }}
          />
        </Modal.Body>
      </ModalBody>

      {/* 모달 - 푸터 */}
      <ModalFooter
        style={{
          justifyContent: 'space-between',
          borderTop: '1px solid #e0e0e0',
          position: 'fixed',
          bottom: 0,
          width: '100%',
          background: '#fff',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label style={{ fontSize: '16px' }}>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checked}
              onChange={handleCheckboxChange}
            />
            오늘 하루 보지 않기
          </label>
        </div>
        <Button color="#e5e4e9" onClick={() => closePopup()} autoFocus outline>
          닫기
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PopupComponent;
