/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Form } from 'react-bootstrap';

// Custom Component
import { MainHeader, Navi } from '@components';

// API
import { getAssetList } from '@api/asset/asset';

import { useHistory } from 'react-router-dom';

// ===================================================================
// [ 보유자산 현황 ]
// ===================================================================
export default React.memo(function Assets(props) {
  // ===================================================================
  // [ States ]
  // ===================================================================

  const history = useHistory();
  // 자산
  const [assetList, setAssetList] = useState([]);

  // ===================================================================
  // [ API ] 보유자산 리스트
  // ===================================================================
  const fetchAssetList = async () => {
    // Axios
    try {
      const { data } = await getAssetList();

      // 성공
      if (data.code === '0000') {
        const list = data.data;
        const emptyList = [];

        for (let i = 0; i < list.length; i += 1) {
          emptyList.push({
            coinType: list[i].coinType,
            balance: list[i].balance,
          });
        }

        setAssetList(emptyList);
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    fetchAssetList();

    return () => {};
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="assets">
      {/* 메인 헤더 */}
      <MainHeader />

      {/* 메인 컨텐츠 */}
      <Container className="container-custom">
        <Form className="scroll">
          {assetList.length > 0 &&
            assetList.map((item, idx) => {
              return (
                <div
                  key={`asset-${idx}`}
                  className="assets-box"
                  onClick={() => {
                    history.push({
                      pathname: `/my-assets/detail`,
                      state: {
                        platform: `${item.coinType}`,
                      },
                    });
                  }}
                >
                  <div className="it-line">
                    <div className="assets-imgbox">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/coin/${item.coinType}.png`}
                        alt={`coin-simbol-${idx}`}
                        className="coin-img"
                      />
                    </div>
                    <p className="assets-text">{item.coinType}</p>
                  </div>
                  <p className="assets-text">{+item.balance}</p>
                </div>
              );
            })}
        </Form>
      </Container>

      {/* 푸터 */}
      <Navi />
    </main>
  );
});
