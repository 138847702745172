import React from 'react';
import { useTranslation } from 'react-i18next';
import Router from './router/Router';

function App() {
  const { t, i18n } = useTranslation();

  return (
    <div className={`App ${t('mode')}`}>
      <Router />
    </div>
  );
}
export default React.memo(App);
