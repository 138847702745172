/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { Header, CustomModal } from '@components';

// ===================================================================
// [ 아이디 찾기 결과 페이지 ]
// ===================================================================
export default React.memo(function FindId(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 계정 정보
  const location = useLocation();
  const { mbId = '', joinDate = '' } = location.state;

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    if (!mbId || !joinDate) {
      openModal({ text: '잘못된 접근입니다.' });
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="matchid">
      {/* 헤더 */}
      <Header title="아이디 찾기" />

      {/* 컨텐츠 */}
      <Container className="container-between">
        {/* 계정 정보 */}
        <Form className="scroll">
          <p className="id-check">고객님의 정보와 일치하는 아이디입니다.</p>
          <div className="id-backgroud">
            {/* 아이디 */}
            <p className="id">{mbId}</p>

            {/* 가입일 */}
            <p className="day">
              {joinDate && `가입일 ${joinDate.split(' ')[0]}`}
            </p>
          </div>
        </Form>

        <div className="btn-area fix-bottom">
          {/* 로그인 페이지 이동 */}
          <Button
            onClick={() => {
              history.push({ pathname: `/` });
            }}
          >
            로그인
          </Button>

          {/* 비밀번호 찾기 페이지 이동 */}
          <Button
            variant="outline-primary"
            onClick={() => {
              history.push({ pathname: `/auth/findPwd` });
            }}
          >
            비밀번호 찾기
          </Button>
        </div>
      </Container>

      {/* 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={() => history.go(-1)}
      />
    </main>
  );
});
