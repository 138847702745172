/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line prettier/prettier
import React, { useRef, useState } from 'react';
import { Container, Form, Button, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { CustomSelect, CustomModal, Header } from '@components';

// API
import { requestNewKyc } from '@api/auth/auth';

// KYC 초기 객체
const initialKycInfo = {
  type: '',
  mbFullName: '',
  mbJuminNo1: '',
  mbJuminNo2: '',
};

// 신분증 종류
const kycTypes = [
  {
    label: '주민등록증',
    value: 'id-card',
  },
  {
    label: '운전면허증',
    value: 'driver-license',
  },
  {
    label: '여권',
    value: 'passport',
  },
];

// ===================================================================
// [ KYC 등록 ]
// ===================================================================
export default React.memo(function IdenCheck(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // KYC 등록 정보
  const [kycType, setKycType] = useState(kycTypes[0]);
  const [kycInfo, setKycInfo] = useState(initialKycInfo);

  // 첨부파일
  const kycFileRef = useRef('');
  const [kycFile, setKycFile] = useState('');

  // 약관
  const [isAgreed, setIsAgreed] = useState(false);

  // 중복 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 신분증 종류 선택
  const kycTypeHandler = value => {
    setKycType(value);
  };

  // 이름 입력
  const nameInputHandler = value => {
    kycInfo.mbFullName = value;
    setKycInfo({ ...kycInfo });
  };

  // 주민번호 앞자리
  const firstJuminInputHandler = value => {
    kycInfo.mbJuminNo1 = value.replace(/\D/g, '');
    setKycInfo({ ...kycInfo });
  };

  // 주민번호 뒷자리
  const secondJuminInputHandler = value => {
    kycInfo.mbJuminNo2 = value.replace(/\D/g, '');
    setKycInfo({ ...kycInfo });
  };

  // 파일 첨부
  const addFileHandler = e => {
    const value = e.target.files[0];
    setKycFile(value);
    e.target.value = null;
  };

  // 첨부 파일 삭제
  const removeFile = () => {
    setKycFile('');
  };

  // 약관
  const agreeCheckHandler = value => {
    setIsAgreed(value);
  };

  // Input 검증
  const isValidForm = () => {
    const resultMap = {
      errorMsg: '',
      isValid: false,
    };

    // 신분증 종류
    if (!kycType) {
      resultMap.errorMsg = '선택한 신분증 종류를 확인해주세요.';
    }

    // 이름 검증
    if (!kycInfo.mbFullName) {
      resultMap.errorMsg = '이름을 확인해주세요.';
    }

    // 주민등록 번호 앞자리
    else if (
      !kycInfo.mbJuminNo1 ||
      !kycInfo.mbJuminNo2 ||
      !/^[0-9]{6}$/.test(kycInfo.mbJuminNo1) ||
      !/^[0-9]{7}$/.test(kycInfo.mbJuminNo2)
    ) {
      resultMap.errorMsg = '주민번호를 확인해주세요.';
    }

    // 파일 확인
    else if (!kycFile) {
      resultMap.errorMsg = '첨부 파일을 확인해주세요.';
    }

    // 약관 확인
    // else if (!isAgreed) {
    //   resultMap.errorMsg = '약관을 확인해주세요.';
    // }

    // 검증 성공
    else {
      resultMap.isValid = true;
    }

    return resultMap;
  };

  // ===================================================================
  // [ API ] KYC 신규등록
  // ===================================================================
  const addNewKyc = async () => {
    // 중복 방지
    if (isSentRequest) {
      openModal({ text: '진행중인 요청입니다.' });
      return;
    }

    // Input 검증
    const validationResult = isValidForm();

    // 에러 메세지 출력
    if (!validationResult.isValid) {
      openModal({ text: validationResult.errorMsg });
      return;
    }

    // FormData
    const formData = new FormData();

    // JSON 파라미터
    const params = {
      type: kycType.value,
      mbFullName: kycInfo.mbFullName,
      mbJuminNo1: kycInfo.mbJuminNo1,
      mbJuminNo2: kycInfo.mbJuminNo2,
      mbJuminNoFull: `${kycInfo.mbJuminNo1}-${kycInfo.mbJuminNo2}`,
    };

    // JSON append
    formData.append(
      'param',
      new Blob([JSON.stringify(params)], { type: 'application/json' }),
    );

    // File append
    formData.append('file', kycFile);

    // 중복 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await requestNewKyc(formData);

      // 성공
      if (data.code === '0000') {
        history.push({ pathname: `/identify/complete` });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="iden-check">
      {/* 헤더 */}
      <Header title="신분증 인증" />

      {/* 컨텐츠 */}
      <Container className="container-between ">
        <Form className="scroll">
          {/* 안내 사항 */}
          <div className="title-agree">
            <h4>신분증 확인</h4>
            <div className="iden-box">
              <div className="img-box" />
              <p>
                신분증(주민등록증, 운전면허증, 여권)에 얼굴을 가리지 않게
                메모(목적, 주민등록번호, 이름)를 부착한 후, 신분증을 들고 있는
                본인의 정면을 촬영해 주세요.
              </p>
            </div>
          </div>

          {/* 입력 FORM */}
          <div>
            {/* 신분증 종류 - 드랍다운 */}
            <Form.Group>
              <Form.Label>신분증 종류</Form.Label>
              <CustomSelect
                options={kycTypes}
                selectedValue={kycType}
                onSelect={kycTypeHandler}
                placeholder="선택"
                className="mt-2"
              />
            </Form.Group>

            {/* 이름 */}
            <Form.Group className="mt-4">
              <Form.Label>이름</Form.Label>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="이름 입력"
                  onChange={e => nameInputHandler(e.target.value)}
                  maxLength={10}
                  value={kycInfo.mbFullName}
                />
              </InputGroup>
            </Form.Group>

            {/* 주민등록번호 */}
            <Form.Group className="mt-4">
              <Form.Label>주민등록번호</Form.Label>
              <InputGroup className="mt-2">
                <div className="input-grid">
                  <Form.Control
                    type="text"
                    placeholder="주민등록번호 앞자리"
                    onChange={e => firstJuminInputHandler(e.target.value)}
                    maxLength={6}
                    value={kycInfo.mbJuminNo1}
                  />
                  <p>-</p>
                  <Form.Control
                    type="text"
                    placeholder="주민등록번호 뒷자리"
                    onChange={e => secondJuminInputHandler(e.target.value)}
                    maxLength={7}
                    value={kycInfo.mbJuminNo2}
                  />
                </div>
              </InputGroup>
            </Form.Group>
          </div>

          {/* 첨부파일 */}
          <div className="bkselect-div">
            {/* 라벨 */}
            <p className="mb-bank">신분증 첨부(2MB 이하)</p>

            {/* Input */}
            <Form.Control
              ref={kycFileRef}
              onChange={addFileHandler}
              type="file"
              accept="image/*, .pdf"
              style={{ display: 'none' }}
            />

            {/* 첨부파일 내역 */}
            {kycFile && (
              <div className="img-btn-box">
                <div className="img-line">
                  <p className="img-text">
                    {kycFile.name}
                    <span className="cancle-btn" onClick={removeFile} />
                  </p>
                </div>
              </div>
            )}

            {/* 파일 선택 버튼 */}
            <Button
              variant="outline-primary"
              onClick={() => kycFileRef.current.click()}
            >
              선택
            </Button>
          </div>

          {/* 약관 */}
          {/* <Form.Group className="check-div"> */}
          {/*  <Form.Check */}
          {/*    type="checkbox" */}
          {/*    label="(필수) 개인정보의 수집 및 이용 동의" */}
          {/*    id="agree1" */}
          {/*    value="agree1" */}
          {/*    checked={isAgreed} */}
          {/*    onChange={e => agreeCheckHandler(e.target.checked)} */}
          {/*  /> */}
          {/*  <Button className="btn-next" /> */}
          {/* </Form.Group> */}

          {/* 등록 */}
          <div className="btn-area">
            <Button
              className="w-100"
              disabled={
                // !isAgreed ||
                !kycInfo.mbFullName ||
                !kycInfo.mbJuminNo1 ||
                !kycInfo.mbJuminNo2 ||
                !kycType ||
                !kycFile
              }
              onClick={addNewKyc}
            >
              등록
            </Button>
          </div>
        </Form>
      </Container>

      {/* 메세지 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
      />
    </main>
  );
});
