/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { Header } from '@components';
import { useHistory } from 'react-router-dom';

// ===================================================================
// [ KYC 인증 신청 완료 ]
// ===================================================================
export default React.memo(function IdenComplete(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // ===================================================================
  // [ Util ]
  // ===================================================================
  const moveAuthCenter = () => {
    history.push({ pathname: `/status/authcenter` });
  };

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="iden-complete">
      {/* 헤더 */}
      <Header title="신분증 인증" />

      {/* 컨텐츠 */}
      <Container className="container-between">
        <Form className="scroll mt-4">
          <div className="mail-div">
            <div className="mail-img" />
            <p className="complete-text">인증 신청이 완료되었습니다.</p>
          </div>
        </Form>
        <div className="btn-area fix-bottom">
          <Button size="lg" onClick={moveAuthCenter}>
            확인
          </Button>
        </div>
      </Container>
    </main>
  );
});
