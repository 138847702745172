import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_AUTH = '/api/v1/auth';

const LOG_OUT = `${API_AUTH}/logout`;
const IS_AUTH_PW = `${API_AUTH}/isAuthPassword`;
const IS_AUTH_TRADE_PW = `${API_AUTH}/isAuthTradePassword`;
const MY_INFO = `${API_AUTH}/myInfo`;
const SEND_SMS_CODE = `${API_AUTH}/sendSMSCode`;
const CONFIRM_SMS_CODE = `${API_AUTH}/confirmSMSCode`;
const SET_NEW_PW = `${API_AUTH}/setNewPassword`;
const SET_NEW_TRADE_PW = `${API_AUTH}/setNewTradePassword`;
const GET_MY_KYC = `${API_AUTH}/getMyKyc`;
const REVOKE_MY_KYC = `${API_AUTH}/revokeMyKyc`;
const REQUEST_NEW_KYC = `${API_AUTH}/requestNewKyc`;
const CHECK_PASSWORD = `${API_AUTH}/checkPassword`;

/* ****************************************************** */
// HTTP HEADER
/* ****************************************************** */

//* 첨부 파일이 있을 경우 ( Content-Type > multipart/form-data )
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 로그아웃
export const logout = data => {
  return AXIOS.post(`${LOG_OUT}`, data);
};

// 로그인 비밀번호 확인
export const isAuthPassword = data => {
  return AXIOS.post(`${IS_AUTH_PW}`, data);
};

// 거래 비밀번호 확인
export const isAuthTradePassword = data => {
  return AXIOS.post(`${IS_AUTH_TRADE_PW}`, data);
};

// 마이페이지 내정보
export const myInfo = data => {
  return AXIOS.get(`${MY_INFO}`, data);
};

// 비밀번호 SMS 인증코드 발송
export const sendSMSCode = data => {
  return AXIOS.post(`${SEND_SMS_CODE}`, data);
};

// 비밀번호 SMS 인증코드 확인
export const confirmSMSCode = data => {
  return AXIOS.post(`${CONFIRM_SMS_CODE}`, data);
};

// 로그인 비밀번호 변경
export const setNewPassword = data => {
  return AXIOS.post(`${SET_NEW_PW}`, data);
};

// 거래 비밀번호 변경
export const setNewTradePassword = data => {
  return AXIOS.post(`${SET_NEW_TRADE_PW}`, data);
};

// 내 KYC 인증 현황
export const getMyKyc = data => {
  return AXIOS.get(`${GET_MY_KYC}`, data);
};

// KYC 인증 해지
export const revokeMyKyc = data => {
  return AXIOS.delete(`${REVOKE_MY_KYC}`, { params: data });
};

// KYC 인증 신청
export const requestNewKyc = data => {
  return AXIOS.post(`${REQUEST_NEW_KYC}`, data, formDataConfig);
};

// 비밀번호 확인
export const checkPassword = data => {
  return AXIOS.post(`${CHECK_PASSWORD}`, data);
};
