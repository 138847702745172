/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line prettier/prettier
import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { Header, CustomModal } from '@components';
import { useHistory, useLocation } from 'react-router-dom';
import {
  checkId,
  signUpComplete,
  sendPhoneVerificationCode,
  sendMailVerificationCode,
  checkPhoneVerificationCode,
} from '@api/auth/openAuth';

// ===================================================================
// [ 회원가입 ]
// ===================================================================
export default React.memo(function Infor(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputAgreeThirdPartyYn, setInputAgreeThirdPartyYn] = useState('');
  const [inputMarketingUseYn, setInputMarketingUseYn] = useState('');
  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [mailNumber, setMailNumber] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [isMailSendButtonDisabled, setIsMailSendButtonDisabled] =
    useState(false);
  const [isMailConfirmButtonDisabled, setIsMailConfirmButtonDisabled] =
    useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailTaken, setIsEmailTaken] = useState(false);
  const [isEmailRegex, setIsEmailRegex] = useState(false);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [isMailVerificationCodeSent, setIsMailVerificationCodeSent] =
    useState(false);
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] =
    useState(false);

  const [sendShow, setSendShow] = useState(false); // 인증번호 발송
  const sendClose = () => setSendShow(false);
  const [confirmShow, setConfirmShow] = useState(false); // 인증번호 다시확인
  const confirmClose = () => setConfirmShow(false);
  const [checkShow, setCheckShow] = useState(false); // 인증완료
  const checkClose = () => setCheckShow(false);

  const [verificationSeq, setVerificationSeq] = useState('');
  const [mailVerificationSeq, setMailVerificationSeq] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(300);
  const [isSent, setIsSent] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isMailConfirmed, setIsMailConfirmed] = useState(false);
  const [isChangePhone, setIsChangePhone] = useState(false);
  const [isChangeMail, setIsChangeMail] = useState(false);
  const [startTimer, setStartTimer] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text, type = '', data = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
    setModalData(data);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  const moveBack = () => {
    history.go(-1);
  };

  const movePage = pathname => {
    history.push({ pathname });
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  const handleEmailChange = e => {
    if (isMailVerificationCodeSent) {
      setIsChangeMail(true); // 인증 완료
    }
    setEmail(e.target.value);
    setIsEmailInvalid(false);
  };

  const handlePasswordChange = e => {
    setInputPassword(e.target.value);
    setIsPasswordInvalid(false);
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    setIsConfirmPasswordInvalid(false);
  };

  const handleName = e => {
    setInputName(e.target.value);
  };

  const handlePhone = e => {
    if (isVerificationCodeSent) {
      setIsChangePhone(true); // 인증 완료
    }
    setPhone(e.target.value);
  };

  const handleNumber = e => {
    setNumber(e.target.value);
  };

  const handleMailNumber = e => {
    setMailNumber(e.target.value);
  };

  // 이메일 검증
  const validateEmail = async () => {
    await checkEmailRegex();
    await checkInputId();
  };

  // 패스워드 검증
  const validatePassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,12}$/;
    const isValid = passwordRegex.test(inputPassword);
    setIsConfirmPasswordInvalid(inputPassword !== confirmPassword);
    setIsPasswordInvalid(!isValid);
  };

  // 이메일 정규식 검증
  const checkEmailRegex = async () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const isValid = emailRegex.test(email);
    setIsEmailInvalid(!isValid);
    setIsEmailRegex(!isValid);
  };

  // 이메일 중복 검증
  const checkInputId = async () => {
    const emailParam = { id: email };
    try {
      const { data } = await checkId(emailParam);
      if (data.code === '1300') {
        setIsEmailInvalid(true);
        setIsEmailTaken(true);
      } else {
        setIsEmailTaken(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 이름 정규식 검증
  const checkNameRegex = async () => {
    const nameRegex = /^[가-힣]{2,4}$/;
    const isValid = nameRegex.test(inputName);
    setIsNameValid(isValid);
  };

  // 타이머 포맷팅
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleVerificationCodeClick = async () => {
    await sendCode();
  };

  const handleResendClick = async () => {
    await sendCode();
  };

  const handleMailResendClick = async () => {
    await sendMailCode();
  };

  // ===================================================================
  // [ API ]
  // ===================================================================
  const sendCode = async () => {
    if (isConfirmed) {
      openModal({ text: '이미 인증이 완료되었습니다.' });
      return;
    }

    if (!phone) {
      openModal({ text: '핸드폰 번호를 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      phoneNo: phone,
    };

    // Axios
    try {
      const { data } = await sendPhoneVerificationCode(params);
      if (data.code === '0000') {
        setTimeRemaining(300);
        setIsChangePhone(false);
        setIsVerificationCodeSent(true);
        setVerificationSeq(data.data.seq);
        setSendShow(true);
        setStartTimer(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsVerificationCodeSent(false);
    }
  };

  const checkCode = async () => {
    if (isChangePhone) {
      openModal({
        text: '전화번호가 인증번호 발송 이후에 변경되었습니다. 다시 인증을 진행해주세요.',
      });
      return;
    }

    if (isConfirmed) {
      openModal({ text: '인증번호를 입력해 주세요.' });
      return;
    }

    if (!number) {
      openModal({ text: '인증번호를 입력해 주세요.' });
      return;
    }

    // JSON Param
    const params = {
      code: number,
      seq: verificationSeq,
    };

    // Axios
    try {
      const { data } = await checkPhoneVerificationCode(params);
      if (data.code === '0000') {
        setCheckShow(true);
        setIsConfirmed(true); // 인증 완료
        setIsSendButtonDisabled(true); // 인증번호 발송 버튼 비활성화
        setIsConfirmButtonDisabled(true); // 인증 버튼 비활성화
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsVerificationCodeSent(true);
    }
  };

  const sendMailCode = async () => {
    if (isMailConfirmed) {
      openModal({ text: '이미 인증이 완료되었습니다.' });
      return;
    }

    if (!email) {
      openModal({ text: '이메일 주소를 입력해 주세요.' });
      return;
    }

    // JSON Param
    const params = {
      mailAddress: email,
    };

    // Axios
    try {
      const { data } = await sendMailVerificationCode(params);
      if (data.code === '0000') {
        setIsChangeMail(false);
        setIsMailVerificationCodeSent(true);
        setMailVerificationSeq(data.data.seq);
        setSendShow(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsMailVerificationCodeSent(false);
    }
  };

  const checkMailCode = async () => {
    if (isChangeMail) {
      openModal({
        text: '메일주소가 인증번호 발송 이후에 변경되었습니다. 다시 인증을 진행해주세요.',
      });
      return;
    }

    if (isMailConfirmed) {
      openModal({ text: '인증번호를 입력해 주세요.' });
      return;
    }

    if (!mailNumber) {
      openModal({ text: '인증번호를 입력해 주세요.' });
      return;
    }

    // JSON Param
    const params = {
      code: mailNumber,
      seq: mailVerificationSeq,
    };

    // Axios
    try {
      const { data } = await checkPhoneVerificationCode(params);
      if (data.code === '0000') {
        setCheckShow(true);
        setIsMailConfirmed(true); // 인증 완료
        setIsMailSendButtonDisabled(true); // 인증번호 발송 버튼 비활성화
        setIsMailConfirmButtonDisabled(true); // 인증 버튼 비활성화
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsMailVerificationCodeSent(true);
    }
  };

  const sendSignUpReq = async () => {
    // 인풋 값 검증
    if (!email) {
      openModal({ text: '이메일을 입력 해 주세요.' });
      return;
    }
    if (!inputPassword || !confirmPassword) {
      openModal({ text: '비밀번호를 확인 해 주세요.' });
      return;
    }
    if (!phone) {
      openModal({ text: '전화번호를 입력 해 주세요.' });
      return;
    }
    if (!inputName) {
      openModal({ text: '이름을 입력 해 주세요.' });
      return;
    }
    if (!isMailConfirmed) {
      openModal({ text: '이메일 인증을 완료했는 지 확인 해 주세요.' });
      return;
    }
    if (!isConfirmed) {
      openModal({ text: '휴대전화 번호 인증을 완료했는 지 확인 해 주세요.' });
      return;
    }

    // JSON Param
    const params = {
      id: email,
      password: inputPassword,
      name: inputName,
      phoneNo: phone,
      certificationSeq: verificationSeq,
      certificationCode: number,
      mailCertificationSeq: mailVerificationSeq,
      mailCertificationCode: mailNumber,
      personalInformationUseYn: inputAgreeThirdPartyYn,
      marketingUseYn: inputMarketingUseYn,
    };

    // Axios
    try {
      const { data } = await signUpComplete(params);
      if (data.code === '0000') {
        openModal({ text: '회원가입이 완료되었습니다.', type: 'complete' });
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsSent(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    if (
      email !== '' &&
      inputPassword !== '' &&
      confirmPassword !== '' &&
      inputName !== '' &&
      phone !== '' &&
      number !== '' &&
      mailNumber !== '' &&
      isConfirmed &&
      isMailConfirmed &&
      !isPasswordInvalid &&
      !isConfirmPasswordInvalid &&
      isNameValid
    ) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [email, inputPassword, confirmPassword, inputName, phone, number, mailNumber, isConfirmed, isMailConfirmed, isPasswordInvalid, isConfirmPasswordInvalid, isNameValid]);

  useEffect(() => {
    let intervalId;

    if (startTimer) {
      intervalId = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);
    }

    if (timeRemaining === 0) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [startTimer, timeRemaining]);

  useEffect(() => {
    setInputAgreeThirdPartyYn(
      location.state.find(item => item.type === 'agreeThirdParty')?.isChecked ||
        'N',
    );
    setInputMarketingUseYn(
      location.state.find(item => item.type === 'marketingUse')?.isChecked ||
        'N',
    );
    // const isSignUpMember = location.state
    //   ? location.state.isSignUpMember
    //   : false;
    // setInputPersonalUseInfoYn(
    //   location.state ? location.state.personalInfoUseYn : '',
    // );
    // setInputMarketingUseYn(location.state ? location.state.marketingUseYn : '');
    // if (!isSignUpMember) {
    //   history.push({ pathname: `/` });
    // }
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="infor">
      {/* <Header title="회원가입" /> */}
      <Header title="회원가입" />
      <Container className="container-between ">
        <Form className="scroll">
          <div className="title-agree">
            <h4>정보입력</h4>
            <p>회원가입을 위한 정보를 입력해주세요.</p>
          </div>
          <div>
            <Form.Group className="mt-4">
              <Form.Label>아이디</Form.Label>
              <div className="grid-btn">
                <Form.Control
                  type="text"
                  placeholder="아이디(이메일) 입력"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={validateEmail}
                  isInvalid={isEmailInvalid}
                  disabled={isMailConfirmed}
                />
                {isVerificationCodeSent ? (
                  <Button
                    variant="outline-primary"
                    onClick={handleMailResendClick}
                    disabled={isMailSendButtonDisabled}
                  >
                    재전송
                  </Button>
                ) : (
                  <Button
                    variant="outline-primary"
                    disabled={isEmailInvalid}
                    onClick={sendMailCode}
                  >
                    인증번호
                  </Button>
                )}
                {isEmailRegex && (
                  <Form.Control.Feedback type="invalid">
                    이메일 주소 형식이 올바르지 않습니다.
                  </Form.Control.Feedback>
                )}
                {isEmailTaken && (
                  <Form.Control.Feedback type="invalid">
                    이미 가입된 이메일 주소입니다.
                  </Form.Control.Feedback>
                )}
              </div>
              <div className="grid-btn mt-2">
                <Form.Control
                  type="text"
                  placeholder="인증 번호 입력"
                  value={mailNumber}
                  disabled={isMailConfirmed}
                  onChange={handleMailNumber}
                />
                <Button
                  variant="outline-primary"
                  onClick={checkMailCode}
                  disabled={isMailConfirmButtonDisabled}
                >
                  확인
                </Button>
              </div>
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>비밀번호</Form.Label>
              <Form.Control
                type="password"
                placeholder="영문+숫자 4~12자 이내"
                value={inputPassword}
                onChange={handlePasswordChange}
                onBlur={validatePassword}
                isInvalid={isPasswordInvalid}
              />
              <Form.Control.Feedback type="invalid">
                영문/숫자 조합(4~12자)
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Control
                type="password"
                placeholder="비밀번호 확인"
                className="mt-2"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={validatePassword}
                isInvalid={isConfirmPasswordInvalid}
              />
              <Form.Control.Feedback type="invalid">
                비밀번호가 일치하지 않습니다.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>이름</Form.Label>
              <Form.Control
                type="text"
                placeholder="이름 입력"
                value={inputName}
                onChange={handleName}
                onBlur={checkNameRegex}
                isInvalid={!isNameValid}
              />
              <Form.Control.Feedback type="invalid">
                이름을 정확히 입력하세요.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>휴대전화 번호</Form.Label>
              <div className="grid-btn">
                <Form.Control
                  type="text"
                  placeholder="-없이 숫자만 입력"
                  value={phone}
                  onChange={handlePhone}
                  disabled={isConfirmed}
                />
                {isVerificationCodeSent ? (
                  <Button
                    variant="outline-primary"
                    onClick={handleResendClick}
                    disabled={isSendButtonDisabled}
                  >
                    재전송
                  </Button>
                ) : (
                  <Button
                    variant="outline-primary"
                    disabled={isSendButtonDisabled}
                    onClick={handleVerificationCodeClick}
                  >
                    인증번호
                  </Button>
                )}
              </div>
              <div className="grid-btn mt-2">
                <Form.Control
                  type="text"
                  placeholder="인증 번호 입력"
                  value={number}
                  disabled={isConfirmed}
                  onChange={handleNumber}
                />
                <Button
                  variant="outline-primary"
                  onClick={checkCode}
                  disabled={isConfirmButtonDisabled}
                >
                  확인
                </Button>
              </div>
              {!isConfirmed && (
                <p className="time-text">
                  남은시간 {formatTime(timeRemaining)}
                </p>
              )}
            </Form.Group>
          </div>
          <div className="btn-area">
            <Button
              className="w-100"
              disabled={isButtonDisabled}
              onClick={() => {
                sendSignUpReq();
              }}
            >
              가입완료
            </Button>
          </div>
        </Form>
      </Container>
      {/** 인증번호 발송 */}
      <Modal
        size="sm"
        show={sendShow}
        onHide={() => setSendShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        id="infor-send"
      >
        <Modal.Body>
          인증번호를 발송했습니다. <br />
          인증번호가 오지 않으면 입력하신 정보를 다시 한번 더 확인해 주세요.
        </Modal.Body>
        <Modal.Footer>
          <Button className="modal-btn" onClick={sendClose}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {/** 인증번호 다시확인 */}
      <Modal
        size="sm"
        show={confirmShow}
        onHide={() => setConfirmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        id="infor-send"
      >
        <Modal.Body>
          입력하신 인증번호를
          <br />
          다시 한번 더 확인해 주세요.
        </Modal.Body>
        <Modal.Footer>
          <Button className="modal-btn" onClick={confirmClose}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {/** 인증번호 완료 */}
      <Modal
        size="sm"
        show={checkShow}
        onHide={() => setCheckShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        id="infor-check"
      >
        <Modal.Body>인증이 완료되었습니다.</Modal.Body>
        <Modal.Footer>
          <Button className="modal-btn" onClick={checkClose}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 확인 모달 */}
      <CustomModal
        messageOnly={modalType !== 'delete-image'}
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
        onConfirm={
          modalType === 'denied'
            ? moveBack
            : modalType === 'invalid'
            ? () => movePage('/')
            : modalType === 'complete'
            ? () => movePage('/')
            : closeModal
        }
      />
    </main>
  );
});
