import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_WALLET = '/api/v1/wallet';

const WALLET_ADDRESS = `${API_WALLET}/`;
const WALLET_LIST = `${API_WALLET}/applyList`;
const WALLET_APPLY = `${API_WALLET}/apply`;

/* ****************************************************** */
// HTTP HEADER
/* ****************************************************** */

//* 첨부 파일이 있을 경우 ( Content-Type > multipart/form-data )
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 지갑주소 출력
export const getWalletAddress = data => {
  return AXIOS.post(`${WALLET_ADDRESS}`, data);
};

// 지갑 리스트
export const getWalletList = () => {
  return AXIOS.post(`${WALLET_LIST}`);
};

// 지갑 등록
export const applyWallet = data => {
  return AXIOS.post(`${WALLET_APPLY}`, data, formDataConfig);
};
