/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { Navi, MainHeader, CustomModal } from '@components';

// API
import { getNodeInfo, getNodeList, checkNode } from '@api/node/node';

// 초기 페이징 정보
const initialPagingInfo = {
  pageNo: 1,
  pageSize: 20,
};

// ===================================================================
// [ 아이템 현황 ]
// ===================================================================
export default React.memo(function ShareStatus(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 아이템 내역 ( 통합 아이템, 플랫폼 )
  const [totalNode, setTotalNode] = useState(0); // 이도, 바이너리, BNC, 파일노드
  const [fileNodeBalance, setFileNodeBalance] = useState(0); // 파일노드 벨런스
  const [nodeInfoList, setNodeInfoList] = useState([]);

  // 아이템 처리 목록
  const [totalCount, setTotalCount] = useState(0);
  const [nodeHistory, setNodeHistory] = useState([]);

  // 페이징
  const [paging, setPaging] = useState(initialPagingInfo);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = text => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 페이지 변경
  const pageHandler = () => {
    paging.pageNo += 1;
    setPaging({ ...paging });
  };

  // 상세 페이지 이동
  const moveNodeDetail = seq => {
    history.push({ pathname: '/status/processhistory', state: { seq } });
  };

  // 인증센터 이동
  const moveAuthCenter = seq => {
    history.push({ pathname: `/status/authcenter` });
  };

  // 노드 상태별 옵션 설정 ( null = 미등록, complete = 등록완료, cancle = 반려, stay 승인대기 )
  const convertOptions = status => {
    const returnMap = {
      label: '',
      style: '',
      fn: null,
    };

    switch (status) {
      // 승인대기
      case 'REQ':
        returnMap.label = '승인대기';
        returnMap.style = 'ing';
        returnMap.fn = null;
        break;
      // 승인
      case 'OK':
        returnMap.label = '승인';
        returnMap.style = 'complete';
        returnMap.fn = null;
        break;
      // 반려
      case 'REJ':
        returnMap.label = '반려';
        returnMap.style = 'cancle';
        returnMap.fn = null;
        break;
      default:
        returnMap.label = '';
        returnMap.style = '';
        returnMap.fn = null;
        break;
    }

    return returnMap;
  };

  // ===================================================================
  // [ API ] 아이템 등록 신청
  // ===================================================================
  const requestNewNode = async () => {
    // Axios
    try {
      const { data } = await checkNode();

      // 인증 O
      if (data.code === '0000') {
        history.push({
          pathname: `/status/application`,
          state: { isConfirmed: 'Y' },
        });
      }
    } catch (error) {
      openModal(error.data.message);
    }
  };

  // ===================================================================
  // [ API ] 통합 아이템
  // ===================================================================
  const getNodeSummary = async () => {
    // Axios
    try {
      const { data } = await getNodeInfo();

      if (data.code === '0000') {
        const emptyList = [];
        const info = data.data;

        // BINARY
        if (info.binary)
          emptyList.push({
            label: info.binaryHan,
            amount: info.binary,
          });

        // BNC
        if (info.bnc) emptyList.push({ label: info.bncHan, amount: info.bnc });

        // EDO
        if (info.edo) emptyList.push({ label: info.edoHan, amount: info.edo });

        // FILENODE
        if (info.filenode)
          emptyList.push({
            label: info.fileNodeHan,
            amount: info.fileNode,
          });

        // 데이터 갱신 ( 통합 아이템, 아이템 현황 )
        setTotalNode(info.binary + info.bnc + info.edo + info.fileNode);
        setNodeInfoList(emptyList);

        // 파일노드 벨런스
        setFileNodeBalance(+info.filenodeBalance);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // ===================================================================
  // [ API ] 아이템 처리 목록
  // ===================================================================
  const getNodeStatusList = async () => {
    // Axios
    try {
      const { data } = await getNodeList(paging);

      if (data.code === '0000') {
        const list = data.data?.contents || [];

        // 데이터 갱신
        setTotalCount(data.data?.totalCount || 0);
        setNodeHistory([...nodeHistory, ...list]);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================

  // 최초 호출
  useEffect(() => {
    getNodeSummary();
    getNodeStatusList();

    return () => {};
  }, []);

  // 페이지 갱신
  useEffect(() => {
    getNodeStatusList();

    return () => {};
  }, [paging]);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="share-status">
      {/* 헤더 */}
      <MainHeader />

      {/* 컨텐츠 */}
      <Container className="container-custom">
        {/* 아이템 현황 */}
        <div className="status-div">
          <div className="status-box">
            {/* 라벨 (1) - 통합 아이템 */}
            <h6>통합 아이템</h6>

            {/* 통합 내역 */}
            <div className="node-box">
              <p>{totalNode || 0} 노드</p>
            </div>

            {/* 상세 내역 */}
            {nodeInfoList.map((item, idx) => {
              return (
                <div key={`node-history-list-${idx}`} className="flex-list-box">
                  <div key={`node-list-${idx + 1}`} className="flex-list">
                    <p>{item.label}</p>
                    <p>{item.amount}</p>
                  </div>
                </div>
              );
            })}

            <hr />

            {/* 라벨 (2) - 파일노드 */}
            <h6>파일노드</h6>

            {/* 통합 내역 */}
            <div className="node-box">
              <p>{fileNodeBalance || 0} 노드</p>
            </div>

            {/* 본인 계정 구매 아이템 승인신청 */}
            <Button
              variant="outline-primary"
              className="add-btn mt-3"
              onClick={requestNewNode}
            >
              본인 계정 구매 아이템 승인신청
            </Button>
          </div>
        </div>

        {/* 아이템 처리 목록 */}
        <div className="list-title">
          {/* 라벨 */}
          <p>아이템 처리 목록</p>

          {/* 총 처리 수 */}
          <p>
            <span>{totalCount}</span>건
          </p>
        </div>

        {/* 처리 내역 상세 */}
        <Form className="scroll">
          <div className="list scroll">
            {/* 리스트 */}
            {nodeHistory.length > 0 &&
              nodeHistory.map((item, idx) => (
                <div
                  key={`node-list-${idx}`}
                  className="list-border"
                  onClick={() => moveNodeDetail(item.seq)}
                >
                  <div className="list-box">
                    <div className="box-title">
                      {/* 등록일 */}
                      <p className="box-day">{item.regDate.split(' ')[0]}</p>

                      {/* 노드 승인 상태 */}
                      <p
                        className={`box-span ${
                          convertOptions(item.status).style
                        }`}
                      >
                        {convertOptions(item.status).label}
                      </p>
                    </div>

                    {/* 상세 내용 */}
                    <p className="status-title">{item.title}</p>
                  </div>
                </div>
              ))}

            {/* 더보기 - 페이징 */}
            {totalCount > nodeHistory.length && (
              <div className="add-onbox" onClick={pageHandler}>
                <p>더보기</p>
                <i className="material-icons">expand_more</i>
              </div>
            )}
          </div>
        </Form>
      </Container>

      {/* 푸터 */}
      <Navi />

      {/* 확인 모달 */}
      <CustomModal
        messageOnly={false}
        contentText={modalText}
        confirmBtnText="인증하기"
        display={modalShow}
        onHide={closeModal}
        onConfirm={moveAuthCenter}
      />
    </main>
  );
});
