/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

// Custom Component
import { Header } from '@components';

// API
import { getClause } from '@api/more/openMore';

// ===================================================================
// [ 약관 ]
// ===================================================================
export default React.memo(function Agreement(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const location = useLocation();
  const [clause, setClause] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  const clauseType = searchParams.get('type');

  // ===================================================================
  // [ Util ]
  // ===================================================================
  const noticeDetail = async () => {
    const param = {
      type: clauseType,
    };

    try {
      const { data } = await getClause(param);
      if (data.code === '0000') {
        setClause(data.data);
      }
    } catch (error) {
      // TODO ::: 후처리
      console.error(error);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(async () => {
    await noticeDetail();
  }, [clauseType]);

  // ===================================================================
  // [ Return ]
  // ===================================================================
  return (
    <main id="notice-detail">
      {/* 헤더 */}
      <Header title="약관" isCloseBtn={false} />

      {/* 컨텐츠 */}
      <Container className="container-between">
        {/* 약관 */}
        <Form className="scroll">
          {/* 약관 - 타이틀 */}
          <div className="detail-titlebox">
            <div className="detail-box">
              <h6>{clause.title}</h6>
            </div>
          </div>

          {/* 약관 - 본문 */}
          <div
            className="detail-body"
            dangerouslySetInnerHTML={{ __html: clause.contents }}
          />
        </Form>
      </Container>
    </main>
  );
});
