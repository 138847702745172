/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line prettier/prettier
import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import {
  Header,
  SmallModal,
  SelfAuthModal,
  TermsModal,
  CustomSelect,
} from '@components';
import { useHistory } from 'react-router-dom';

export default React.memo(function BankAuth(props) {
  const [bank, setBank] = useState(bankOptions[0]);
  const history = useHistory();
  return (
    <main id="bankauth">
      {/* <Header title="회원가입" /> */}
      <Header title="회원가입" />
      <Container className="container-between ">
        <Form className="scroll">
          <div className="title-agree">
            <h4>은행 계좌 인증</h4>
          </div>
          <div>
            <Form.Group>
              <Form.Label>거래은행</Form.Label>
              <CustomSelect
                options={bankOptions}
                selectedValue={bank}
                placeholder="선택"
                onChange={setBank}
                className="mt-2"
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>계좌번호</Form.Label>
              <InputGroup className="mt-2">
                <Form.Control type="text" placeholder="숫자로만 입력" />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mt-4">
              <Form.Label>예금주</Form.Label>
              <InputGroup className="mt-2">
                <Form.Control
                  type="text"
                  placeholder="계좌에 등록된 예금주 입력"
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="bkselect-div">
            <p className="mb-bank">통장사본 첨부(2MB 이하)</p>
            <p className="mb-text">
              이름과 계좌번호가 명확하게 표시된 은행 계좌 사본 이미지를 업로드
              해주세요.
            </p>
            <Button variant="outline-primary">선택</Button>
          </div>
        </Form>
        <div className="btn-area">
          <Button
            className="w-100"
            onClick={() => {
              history.push({ pathname: `/auth/identify` });
            }}
          >
            다음
          </Button>
        </div>
      </Container>
    </main>
  );
});
const bankOptions = [
  {
    label: '국민은행',
  },
  {
    label: '신한은행',
  },
  {
    label: '농협은행',
  },
];
