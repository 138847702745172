/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useEffect } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { Header, SmallModal, CustomModal } from '@components';
import { useHistory } from 'react-router-dom';
import { getBank } from '@api/bank/bank';
import { deleteBank } from '../../api/bank/bank';

const initialAccountInfo = {
  seq: '',
  bankCode: '',
  bankName: '',
  bankOwner: '',
  admMemo: '',
  address: '',
  status: '',
  statusHan: '',
  modDate: '',
  regDate: '',
  confirmDate: '',
};

// 지갑 초기 스타일 & 라벨 & 이벤트
const initialAccountOptions = {
  label: '',
  style: '',
  fn: null,
};

export default React.memo(function BankAccountClear(props) {
  const history = useHistory();
  const [accountInfo, setAccountInfo] = useState(initialAccountInfo);
  const [accountOptions, setAccountOptions] = useState(initialAccountOptions);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [accountClear, setAccountClear] = useState(true); // 초기화 여부
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // 메세지 모달
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [messageModalText, setMessageModalText] = useState('');
  const [messageModalType, setMessageModalType] = useState('');

  // 메세지 모달 출력
  const openMessageModal = ({ text, type = '' }) => {
    setMessageModalText(text);
    setMessageModalShow(true);
    setMessageModalType(type);
  };
  // 메세지 모달 닫기
  const closeMessageModal = () => {
    setMessageModalShow(false);
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleCloseModal = () => {
    closeModal();
  };

  const handleClear = () => {
    setAccountClear(true);
    openModal();
  };

  // 초기화 확인 모달을 여는 함수
  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  // 초기화 확인 모달을 닫는 함수
  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
    history.push('/status/authcenter');
  };

  // 초기화 버튼 클릭 이벤트 핸들러
  const handleInitializeClick = async () => {
    // 승인 상태 확인
    if (accountInfo.status !== 'OK') {
      openMessageModal({ text: '승인된 계좌만 초기화가 가능합니다.' });
      return;
    }
    const { data } = await deleteBank(accountInfo.seq);
    console.log(data);
    if (data.code === '0000') {
      closeModal();
      openConfirmationModal();
    }
  };

  const convertOptions = (type, status) => {
    if (type === 'bank') {
      switch (status) {
        // 승인대기
        case 'REQ':
          accountOptions.label = '승인대기';
          accountOptions.style = 'stay';
          break;
        // 승인
        case 'OK':
          accountOptions.label = '승인';
          accountOptions.style = 'complete';
          break;
        // 반려
        case 'REJ':
          accountOptions.label = '반려';
          accountOptions.style = 'cancle';
          break;
        // 초기화
        case 'CAN':
          accountOptions.label = '초기화';
          accountOptions.style = 'cancle';
          break;
        default:
          accountOptions.label = '승인대기';
          accountOptions.style = 'stay';
          break;
      }

      setAccountOptions({ ...accountOptions });
    }
  };

  // 지갑 상태 및 라벨 설정
  useEffect(() => {
    convertOptions('bank', accountInfo.status);

    return () => {};
  }, [accountInfo]);

  // KYC, 지갑 정보 조회
  useEffect(() => {
    getMyBankStatus();
    return () => {};
  }, []);

  // ===================================================================
  // [ API ] KYC 인증현황
  // ===================================================================
  const getMyBankStatus = async () => {
    // Axios
    try {
      const { data } = await getBank();
      // 성공
      if (data.code === '0000') {
        if (data.data) {
          // KYC 데이터 주입
          setAccountInfo({
            seq: data.data.seq,
            address: data.data.address,
            bankName: data.data.bankName,
            bankOwner: data.data.bankOwner,
            bankCode: data.data.bankCode,
            status: data.data.status,
            statusHan: data.data.statusHan,
            confirmDate: data.data.confirmDate,
            admMemo: data.data.admMemo,
            regDate: data.data.regDate,
          });
        }
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      // setIsConfirmed(false);
    }
  };

  function moveAccountCheck() {
    history.push('/account/check'); // 이동할 경로
  }

  return (
    <main id="account-auth">
      {/** 헤더에 x표시말고는 다 제거해주세요.. */}
      <Header title="은행 계좌 인증" />
      <Container className="container-between">
        <Form className="scroll mt-4 bank-form">
          <div className="account-box">
            <div className="title-box">
              <p className="title-account">실명확인 계좌 인증 정보</p>
              <p className={`label ${accountOptions.style}`}>
                {accountOptions.label}
              </p>
            </div>
            <div className="bank-info">
              {/* <div className="ic-bank">{}</div> */}
              <p>{accountInfo.bankName}</p>
            </div>
            <p className="bank-account">{accountInfo.address}</p>
            <div className="name">{accountInfo.bankOwner}</div>
            {(accountInfo.status === 'REJ' || accountInfo.status === 'CAN') && (
              <>
                <div className="reasonbox" style={{ paddingTop: 10 }}>
                  {/* 라벨 */}
                  <hr />
                  <p
                    className="reason-title"
                    style={{ fontSize: 20, color: '#fa4d56', fontWeight: 700 }}
                  >
                    반려 사유
                  </p>

                  {/* 반려 사유 */}
                  <p className="reason-body">{accountInfo.admMemo}</p>
                </div>

                {/* 재등록 */}
                <Button
                  variant="outline-primary mt-3"
                  className="wb-btn"
                  onClick={() => moveAccountCheck()}
                  style={{ width: '100%' }}
                >
                  재등록
                </Button>
              </>
            )}
          </div>
          <Button className="clear-btn" onClick={handleClear}>
            계정 정보 초기화
          </Button>
          <Modal
            show={modalIsOpen}
            onHide={handleCloseModal}
            className="clearmodal withdrawmodal"
            backdrop="static" // 배경 클릭시 반응 없음
          >
            <Modal.Header closeButton>
              <Modal.Title>정보 초기화</Modal.Title>
            </Modal.Header>
            <Modal.Body>계좌 정보를 초기화 하시겠습니까?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                취소
              </Button>
              <Button variant="secondary" onClick={handleInitializeClick}>
                초기화
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showConfirmationModal}
            onHide={closeConfirmationModal}
            className="withdrawmodal"
            backdrop="static" // 배경 클릭시 반응 없음
          >
            <Modal.Body>고객님의 계좌정보가 초기화 되었습니다.</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeConfirmationModal}>
                확인
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
        {/* 메세지 모달 */}
        <CustomModal
          contentText={messageModalText}
          headerDisplay={false}
          display={messageModalShow}
          onHide={
            messageModalType === 'denied'
              ? () => history.go(-1)
              : closeMessageModal
          }
        />
      </Container>
    </main>
  );
});
