import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_OPEN_AUTH = '/api/v1/open/auth';

const SIGN_IN = `${API_OPEN_AUTH}/signIn`;
const SEND_MAIL_CODE = `${API_OPEN_AUTH}/sendMailVerificationCode`;
const SEND_MOBILE_CODE = `${API_OPEN_AUTH}/sendPhoneVerificationCode`;
const CHECK_MOBILE_CODE = `${API_OPEN_AUTH}/checkPhoneVerificationCode`;
const SINGUP_COMPLETE = `${API_OPEN_AUTH}/signUpComplete`;
const CHECK_ID = `${API_OPEN_AUTH}/checkId`;
const FIND_ID_COMPLETE = `${API_OPEN_AUTH}/findIdComplete`;
const FIND_PW_COMPLETE = `${API_OPEN_AUTH}/findPasswordComplete`;
const RESET_PASSWORD = `${API_OPEN_AUTH}/resetPassword`;
const POPUP_LIST = `${API_OPEN_AUTH}/getPopup`;

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 로그인
export const signIn = data => {
  return AXIOS.post(`${SIGN_IN}`, data);
};

// 모바일 인증코드 발송
export const sendPhoneVerificationCode = data => {
  return AXIOS.post(`${SEND_MOBILE_CODE}`, data);
};

// 메일 인증코드 발송
export const sendMailVerificationCode = data => {
  return AXIOS.post(`${SEND_MAIL_CODE}`, data);
};

// 모바일 인증코드 확인
export const checkPhoneVerificationCode = data => {
  return AXIOS.post(`${CHECK_MOBILE_CODE}`, data);
};

// 회원가입 완료
export const signUpComplete = data => {
  return AXIOS.post(`${SINGUP_COMPLETE}`, data);
};

// 아이디 중복 확인
export const checkId = data => {
  return AXIOS.post(`${CHECK_ID}`, data);
};

// 아이디 찾기 완료
export const findIdComplete = data => {
  return AXIOS.post(`${FIND_ID_COMPLETE}`, data);
};

// 비밀번호 찾기 완료
export const findPasswordComplete = data => {
  return AXIOS.post(`${FIND_PW_COMPLETE}`, data);
};

// 비밀번호 초기화
export const resetPassword = data => {
  return AXIOS.post(`${RESET_PASSWORD}`, data);
};

// 팝업 정보 들고오기
export const getPopup = data => {
  return AXIOS.get(`${POPUP_LIST}`, data);
};
