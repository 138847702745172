import AXIOS from '@api/axios';

/* ****************************************************** */
// API URL
/* ****************************************************** */

const API_BANK = '/api/v1/bank';

const BANK_ADDRESS = `${API_BANK}/`;
const BANK_INFO = `${API_BANK}/applyList`;
const BANK_LIST = `${API_BANK}/getBankList`;
const BANK_APPLY = `${API_BANK}/apply`;
const BANK_REMOVE = `${API_BANK}/deleteBankInfo`;

/* ****************************************************** */
// HTTP HEADER
/* ****************************************************** */

//* 첨부 파일이 있을 경우 ( Content-Type > multipart/form-data )
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/* ****************************************************** */
// API CALL
/* ****************************************************** */

// 지갑주소 출력
export const getBankAddress = data => {
  return AXIOS.post(`${BANK_ADDRESS}`, data);
};

// 계좌 정보
export const getBank = () => {
  return AXIOS.get(`${BANK_INFO}`);
};

// 은행 리스트
export const getBankList = () => {
  return AXIOS.get(`${BANK_LIST}`);
};

// 계좌 등록
export const applyBank = data => {
  return AXIOS.post(`${BANK_APPLY}`, data, formDataConfig);
};

// 계좌 등록
export const deleteBank = data => {
  return AXIOS.delete(`${BANK_REMOVE}/${data}`);
};
