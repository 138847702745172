/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { Header } from '@components';

// API
import { getGuide } from '@api/more/more';

// ===================================================================
// [ 공지사항 ]
// ===================================================================
export default React.memo(function Guide(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 이용가이드
  const [guideList, setGuideList] = useState([]); // 카테고리별 전체 리스트
  const [guideListAll, setGuideListAll] = useState([]); // 전체 리스트 (초기 데이터)
  const [guideTypeList, setGuideTypeList] = useState([]); // 카테고리 리스트

  // 로딩 스피너
  const [loading, setLoading] = useState(false);

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // 탭 변경
  const tabChangeHandler = key => {
    tabListHandler(key);
  };

  // 탭 리스트 필터링
  const tabListHandler = (type = '') => {
    // 선택 탭
    const selectedTab =
      type ||
      guideListAll.map(item => {
        return item.boardType;
      })[0];

    // 리스트
    const filteredList = guideListAll.filter(
      item => item.boardType === selectedTab,
    );

    setGuideList(filteredList[0].guideList);
  };

  // ===================================================================
  // [ API ] 이용가이드 전체 가져오기
  // ===================================================================
  const getGuideList = async () => {
    if (!loading) {
      setLoading(true);
      try {
        const { data } = await getGuide();

        if (data.code === '0000') {
          const list = data.data;

          // 탭 헤더
          setGuideTypeList(
            list.map(item => {
              return item.boardType;
            }),
          );

          // 탭 컨텐츠
          setGuideListAll([...list]);
        }
      } catch (error) {
        // console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  // 최초 접근 > 이용가이드 전체 가져오기
  useEffect(() => {
    getGuideList();
  }, []);

  // 최초 접근 > 이용가이드 탭 & 리스트 갱신
  useEffect(() => {
    if (guideListAll.length > 0) tabListHandler();
  }, [guideListAll]);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="guide-main">
      {/* 헤더 */}
      <Header title="이용가이드" isCloseBtn={false} />

      {/* 탭 - 헤더 */}
      {guideTypeList && (
        <div id="Tab-css">
          <Tabs
            defaultActiveKey={guideTypeList[0]}
            justify
            onSelect={activeKey => tabChangeHandler(activeKey)}
          >
            {guideTypeList.length > 0 &&
              guideTypeList.map((item, idx) => {
                return (
                  <Tab
                    key={`guide-header-${idx + 1}`}
                    eventKey={item}
                    title={item}
                  />
                );
              })}
          </Tabs>
        </div>
      )}

      {/* 텝 - 컨텐츠 */}
      <Container className="container-between">
        {/* 이용가이드 리스트 */}
        <div className="scroll guide-line">
          {guideList &&
            guideList.map(item => (
              <div
                className="guide-box"
                key={item.seq}
                onClick={() => {
                  history.push({
                    pathname: `/myinfo/guide/detail`,
                    state: {
                      seq: item.seq,
                      type: item.boardType,
                      title: item.title,
                      contents: item.contents,
                    },
                  });
                }}
              >
                <div className="guide-textbox">
                  <div className="guide-titlebox">
                    <p className="guide-title">{item.title}</p>
                  </div>
                </div>
                <i className="material-icons">arrow_forward_ios</i>
              </div>
            ))}
        </div>
      </Container>
    </main>
  );
});
