import React from 'react';
import { Switch } from 'react-router-dom';

// BNC
import Errors from '@pages/Errors';

import SignIn from '@pages/Auth/SignIn';
import SignUp from '@pages/Auth/SignUp';
import FindId from '@pages/Auth/FindId';
import FindPwd from '@pages/Auth/FindPwd';
import Confirmation from '@pages/Auth/Confirmation';
import BankAuth from '@pages/Auth/BankAuth';
import Identify from '@pages/Auth/Identify';
import Infor from '@pages/Auth/Infor';
import MatchId from '@pages/Auth/MatchId';
import PwdResetLink from '@pages/Auth/PwdResetLink';
import PwdNew from '@pages/Auth/PwdNew';
import Agreement from '@pages/Auth/Agreement';

// 아이템 현황
import ShareStatus from '@pages/Status/ShareStatus'; // 아이템 현황
import AuthCenter from '@pages/Status/AuthCenter'; // 더보기 (인증센터)
import Application from '@pages/Status/Application'; // 아이템 증명 양식 선택
import AddApplication from '@pages/Status/AddApplication'; // 아이템 증명 신청서
import ProcessHistory from '@pages/Status/ProcessHistory'; // 아이템 처리 내역

// 내지갑
import MyWallet from '@pages/Wallet/MyWallet'; // 내 지갑 주소 등록
import AddWallet from '@pages/Wallet/AddWallet'; // 지갑 등록 신청
import ReAddWallet from '@pages/Wallet/ReAddWallet'; // 지갑 등록 재신청

// 보유자산
import Assets from '@pages/Assets/Assets';
import AssetsDetail from '@pages/Assets/AssetsDetail'; // 보유자산 상세
import AssetsWithdraw from '@pages/Assets/AssetsWithdraw'; // 보유자산 출금
import AssetsWithdrawDetail from '@pages/Assets/AssetsWithdrawDetail'; // 보유자산 출금

// 내정보
import MyInfo from '@pages/MyInfo/MyInfo'; // 내정보
import InfoEdit from '@pages/MyInfo/InfoEdit'; // 내정보수정
import Notice from '@pages/MyInfo/Notice'; // 공지
import NoticeDetail from '@pages/MyInfo/NoticeDetail'; // 공지사항디테일
import Clause from '@pages/MyInfo/Clause'; // 약관
import Guide from '@pages/MyInfo/Guide'; // 이용가이드
import GuideDetail from '@pages/MyInfo/GuideDetail'; // 이용가이드 상세
import OpenGuide from '@pages/MyInfo/OpenGuide'; // 이용가이드
import OpenGuideDetail from '@pages/MyInfo/OpenGuideDetail'; // 이용가이드 상세

// 신분증 인증
import IdenAuth from '@pages/Identify/IdenAuth'; // 신분증 인증
import IdenClear from '@pages/Identify/IdenClear'; // 신분증 해제
import IdenCheck from '@pages/Identify/IdenCheck'; // 신분증 확인
import IdenComplete from '@pages/Identify/IdenComplete'; // 신분증확인완료

// 은행 계좌 인증
import BankAccountAuth from '@pages/BankAccount/BankAccountAuth'; // 출금 계좌 정보 재등록
import BankAccountCheck from '@pages/BankAccount/BankAccountCheck'; // 은행 계좌 인증
import BankAccountComplete from '@pages/BankAccount/BankAccountComplete'; // 은행 계좌 인증
import BankAccountClear from '@pages/BankAccount/BankAccountClear'; // 은행 계좌 인증

// 레이아웃
import Layout from './Layout';
import AuthLayout from './AuthLayout';

function Router() {
  return (
    <Switch>
      {/* Default ( 로그인 or 아이템현황 ) */}
      <Layout exact path="/" component={SignIn} />

      {/* Auth */}
      <Layout exact path="/auth/signup" component={SignUp} />
      <Layout exact path="/auth/confirmation" component={Confirmation} />
      <Layout exact path="/auth/bankauth" component={BankAuth} />
      <Layout exact path="/auth/identify" component={Identify} />
      <Layout exact path="/auth/infor" component={Infor} />
      <Layout exact path="/auth/findId" component={FindId} />
      <Layout exact path="/auth/matchid" component={MatchId} />
      <Layout exact path="/auth/findPwd" component={FindPwd} />
      <Layout exact path="/auth/pwdresetlink" component={PwdResetLink} />
      <Layout exact path="/auth/pwdnew" component={PwdNew} />
      <Layout exact path="/auth/agreement" component={Agreement} />

      {/* 아이템현황 */}
      <AuthLayout exact path="/sharestatus" component={ShareStatus} />
      <AuthLayout exact path="/status/authcenter" component={AuthCenter} />
      <AuthLayout exact path="/status/application" component={Application} />
      <AuthLayout
        exact
        path="/status/addapplication"
        component={AddApplication}
      />
      <AuthLayout
        exact
        path="/status/processhistory"
        component={ProcessHistory}
      />

      {/* 내지갑 */}
      <AuthLayout exact path="/wallet" component={MyWallet} />
      <AuthLayout exact path="/wallet/addwallet" component={AddWallet} />
      <AuthLayout exact path="/wallet/readdwallet" component={ReAddWallet} />

      {/* 보유자산 */}
      <AuthLayout exact path="/my-assets" component={Assets} />
      <AuthLayout exact path="/my-assets/detail" component={AssetsDetail} />
      <AuthLayout exact path="/my-assets/withdraw" component={AssetsWithdraw} />
      <AuthLayout
        exact
        path="/my-assets/withdrawdetail"
        component={AssetsWithdrawDetail}
      />

      {/* 내정보 */}
      <AuthLayout exact path="/myinfo" component={MyInfo} />
      <AuthLayout exact path="/myinfo/edit" component={InfoEdit} />

      {/* 공지사항 */}
      <AuthLayout exact path="/myinfo/notice" component={Notice} />
      <AuthLayout exact path="/myinfo/notice/detail" component={NoticeDetail} />

      {/* 약관 */}
      <AuthLayout exact path="/myinfo/clause" component={Clause} />

      {/* 이용가이드 */}
      <AuthLayout exact path="/myinfo/guide" component={Guide} />
      <AuthLayout exact path="/myinfo/guide/detail" component={GuideDetail} />
      <Layout exact path="/myinfo/oepnGuide" component={OpenGuide} />
      <Layout
        exact
        path="/myinfo/openGuide/detail"
        component={OpenGuideDetail}
      />

      {/* 신분증 */}
      <AuthLayout exact path="/identify" component={IdenAuth} />
      <AuthLayout exact path="/identify/clear" component={IdenClear} />
      <AuthLayout exact path="/identify/check" component={IdenCheck} />
      <AuthLayout exact path="/identify/complete" component={IdenComplete} />

      {/* 은행 계좌 */}
      <AuthLayout exact path="/account/auth" component={BankAccountAuth} />
      <AuthLayout exact path="/account/check" component={BankAccountCheck} />
      <AuthLayout
        exact
        path="/account/complete"
        component={BankAccountComplete}
      />
      <AuthLayout exact path="/account/clear" component={BankAccountClear} />

      {/* 404 */}
      <Layout component={SignIn} />
    </Switch>
  );
}

export default Router;
