/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import { getNodeDetailBySeq, checkNode } from '@api/node/node';

// 초기 노드 정보
const initialNodeInfo = {
  applyId: '',
  applyName: '',
  applyPhoneNo: '',
  companyName: '',
  status: '',
  title: '',
  nodeApplyAmount: 0,
  raceApplyAmount: 0,
  memo: '',
  confirmDate: '',
  regDate: '',
  admMemo: '',
  tempUrl: [],
};

// ===================================================================
// [ 노드 상세 ]
// ===================================================================
export default React.memo(function ProcessHistory(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 조회 노드 SEQ
  const location = useLocation();
  const seq = location.state?.seq || '';

  // 노드 상세 정보
  const [nodeInfo, setNodeInfo] = useState(initialNodeInfo);

  // 메세지 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 메세지z 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 상태별 옵션 변환
  const convertOptions = status => {
    const returnMap = {
      label: '',
      style: '',
      fn: null,
    };

    switch (status) {
      // 승인대기
      case 'REQ':
        returnMap.label = '승인대기';
        returnMap.style = 'stay';
        returnMap.fn = null;
        break;
      // 승인
      case 'OK':
        returnMap.label = '승인';
        returnMap.style = 'complete';
        returnMap.fn = null;
        break;
      // 반려
      case 'REJ':
        returnMap.label = '반려';
        returnMap.style = 'cancle';
        returnMap.fn = null;
        break;
      default:
        returnMap.label = '';
        returnMap.style = '';
        returnMap.fn = null;
        break;
    }

    return returnMap;
  };

  // 전화번호 포멧
  const formatPhoneNumber = phoneNumber => {
    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };

  // ===================================================================
  // [ API ] 아이템 등록 신청
  // ===================================================================
  const getNodeDetailInfo = async () => {
    // Axios
    try {
      const { data } = await getNodeDetailBySeq(seq);

      if (data.code === '0000') {
        const info = data.data;

        // 데이터 갱신
        setNodeInfo({
          applyId: info?.applyId,
          applyName: info?.applyName || '',
          applyPhoneNo: info?.applyPhoneNo || '',
          companyName: info?.companyName || '',
          status: info?.status || '',
          title: info?.title || '',
          nodeApplyAmount: info?.nodeApplyAmount || 0,
          raceApplyAmount: info?.raceApplyAmount || 0,
          memo: info?.memo || '',
          confirmDate: info?.confirmDate || '',
          regDate: info?.regDate || '',
          admMemo: info?.admMemo || '',
          tempUrl: [...(info?.fileUrl || '')],
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // ===================================================================
  // [ API ] 아이템 신청 페이지로 이동
  // ===================================================================
  const moveAddApplication = async () => {
    // Axios
    try {
      const { data } = await checkNode();

      // 인증 O
      if (data.code === '0000') {
        history.push({
          pathname: `/status/application`,
          state: { isConfirmed: 'Y' },
        });
      }
    } catch (error) {
      openModal({ text: error.data.message });
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================

  // 최초 호출
  useEffect(() => {
    // 접근 유효성 확인
    if (!seq || Number.isNaN(seq)) {
      openModal({ text: '잘못된 접근입니다.' });
    }
    // 노드 정보 확인
    else {
      getNodeDetailInfo();
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="processhistory">
      {/* 헤더 */}
      <Header
        title="아이템 처리 내역"
        onClickBtn={() => history.push('sharestatus')}
      />
      {/* 컨텐츠 */}
      <Container className="container-between">
        <Form className="scroll">
          <div className="history-pd">
            {/* 섹션 - 1 */}
            <div className="title-div">
              {/* 상태 */}
              <p className={`label ${convertOptions(nodeInfo.status).style}`}>
                {convertOptions(nodeInfo.status).label}
              </p>
              {/* 타이틀 */}
              <p className="title">{nodeInfo.title}</p>
              {/* 등록일 */}
              <p className="day">{nodeInfo.regDate.split(' ')[0]}</p>
            </div>

            {/* 섹션 - 2 */}
            <div className="infor-body">
              {/* 신청 아이디 */}
              <div className="infor-flex">
                <p className="fr-text">신청아이디</p>
                <p className="tw-text">{nodeInfo.applyId}</p>
              </div>
              {/* 신청 이름 */}
              <div className="infor-flex">
                <p className="fr-text">신청 이름</p>
                <p className="tw-text">{nodeInfo.applyName}</p>
              </div>
              {/* 노드 신청 수량 */}
              <div className="infor-flex">
                <p className="fr-text">신청 노드 수량</p>
                <p className="tw-text">{+nodeInfo.nodeApplyAmount}</p>
              </div>
              {/* 경주마 신청 수량 */}
              <div className="infor-flex">
                <p className="fr-text">신청 경주마 수량</p>
                <p className="tw-text">{+nodeInfo.raceApplyAmount}</p>
              </div>
              {/* 경주마 신청 수량 */}
              <div className="infor-flex">
                <p className="fr-text">휴대전화 번호</p>
                <p className="tw-text">
                  {formatPhoneNumber(nodeInfo.applyPhoneNo)}
                </p>
              </div>
              {/* 상세내용 */}
              <div className="infor-text">
                {/* 메모 */}
                <p className="fr-text">내용</p>
                <p className="tw-text">
                  {nodeInfo.memo.replace(/(<([^>]+)>)/gi, '')}
                </p>
                {/* 첨부파일 */}
                <div className="img-pd">
                  {nodeInfo.tempUrl.length > 0 &&
                    nodeInfo.tempUrl.map((item, idx) => {
                      return (
                        <div key={`node-img-${idx + 1}`} className="img-src">
                          <img
                            src={item}
                            alt={`node-img-alt-${idx + 1}`}
                            className="process-img"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          {/* 관리자 코멘트 */}
          {nodeInfo.admMemo && nodeInfo.status !== 'REQ' && (
            <div className="admin-div">
              {/* 처리일자 */}
              <div className="admin-flex">
                <p>관리자</p>
                <p>{nodeInfo.admMemo}</p>
              </div>
              {/* 메모 */}
              <p>{nodeInfo.confirmDate.split(' ')[0]}</p>
            </div>
          )}

          {/* 재신청 */}
          {nodeInfo.status === 'REJ' && (
            <div className="btn-area fix-bottom">
              <Button variant="outline-primary" onClick={moveAddApplication}>
                재신청
              </Button>
            </div>
          )}
        </Form>
      </Container>

      {/* 메세지 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={() => history.go(-1)}
      />
    </main>
  );
});
