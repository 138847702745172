/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';

function AssetsHeader({ title, onClickBtn, isCloseBtn, noBtn, opacity }) {
  const history = useHistory();
  const location = useLocation();

  const handleCloseButtonClick = () => {
    // history.push('/my-assets/detail');
    history.goBack();
    // if (onClickBtn) {
    //   onClickBtn();
    // } else {
    //   const currentPath = location.pathname;
    //   if (currentPath.includes('/my-assets/detail')) {
    //     history.push('/my-assets');
    //   } else {
    //     history.goBack();
    //   }
    // }
  };

  return (
    <header
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <Container>
        <div className="header-flex">
          <Button
            variant="icon"
            id="btn-close-left"
            onClick={handleCloseButtonClick}
          />
          <h2 className="ellipsis">{title || ''}</h2>
        </div>
      </Container>
    </header>
  );
}

export default AssetsHeader;
