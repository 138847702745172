import axios from 'axios';
import Swal from 'sweetalert2';

const baseURL = process.env.REACT_APP_API_URL;

/* ****************************************************** */
// HTTP URL, Request Header 설정
/* ****************************************************** */
const AXIOS = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

/* ****************************************************** */
// API > Header > Access Token 주입
/* ****************************************************** */
AXIOS.interceptors.request.use(
  async config => {
    const originalRequest = config;

    try {
      // 토큰 확인
      const accessToken = localStorage.getItem('access_token');

      // 토큰 주입
      if (accessToken) {
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
      }
    } catch (err) {
      // console.log('Failed to set Access Token');
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

/* ****************************************************** */
// API > Resposne 전처리
/* ****************************************************** */
AXIOS.interceptors.response.use(
  //* OK
  response => {
    return response;
  },
  //* ERROR
  async error => {
    try {
      const { response } = error;

      //* HTTP Status >= 400
      if (response.status >= 400) {
        if (response.data && response.data.message) {
          // 에러 메세지 전처리
          let errorMessage = '';

          if (response.data.error) {
            errorMessage = `${response.data.error}<br />`;
          }

          errorMessage += `${response.data.message}`;

          /*
            토큰 만료 > 재발급

            1101 : 토큰이 만료 되었습니다.
          */
          if (response.data.code === '1101') {
            try {
              // 리프레시 토큰 확인 > Authorization 설정
              const refreshToken = localStorage.getItem('refresh_token');
              axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;

              // 재발급요청
              const { data } = await axios.post(
                `${baseURL}/api/v1/auth/refresh`,
              );

              // 재발급 토큰 주입
              if (data.code === '0000') {
                localStorage.setItem('access_token', data.data.accessToken);
                window.location.href = '/'; // 로그인 화면 이동
              } else {
                throw new Error();
              }
              return Promise.resolve(response);
            } catch (err) {
              // 에러 > 재로그인
              localStorage.removeItem('access_token');
              localStorage.removeItem('refresh_token');

              window.location.href = '/'; // 로그인 화면 이동
            }
          }

          /*
            토큰 오류 > 재로그인
           
            1102 : 엑세스 토큰 값이 없습니다.
            1103 : 리프레시 토큰 값이 없습니다.
            1104 : 로그인이 필요합니다.
            1105 : 리프레시 토큰이 아닙니다.
            1106 : 토큰값이 맞지 않습니다.
            1107 : 메인페이지 접속시 토큰값이 일치하지 앖습니다.
            1109 : 접근이 불가능한 계정 입니다.
          */
          const tokenNotValidErrorCodes = [
            '1102',
            '1103',
            '1104',
            '1105',
            '1106',
            '1107',
            '1109',
          ];

          if (tokenNotValidErrorCodes.includes(response.data.code)) {
            return Promise.reject(
              await Swal.fire({
                html: errorMessage,
                confirmButtonText: '확인',
              }).then(result => {
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');

                window.location.href = '/'; // 로그인 화면 이동
              }),
            );
          }

          /*
            기타 후처리 코드
           
            1310 : KYC 인증을 완료해야 본인 계정 구매 아이템 승인신청이 가능합니다. 인증센터로 이동하시겠습니까?
            1317 : 지갑 인증을 완료해야 본인 계정 구매 아이템 승인신청이 가능합니다. 인증센터로 이동하시겠습니까?
          */
          const extraCareCodes = ['1310', '1317'];

          if (extraCareCodes.includes(response.data.code)) {
            return Promise.reject(response);
          }

          /*
            에러 메세지 출력
          */
          Swal.fire({
            icon: 'warning',
            confirmButtonColor: '#161616',
            html: errorMessage,
            confirmButtonText: '확인',
          });
        } else {
          Swal.fire({
            icon: 'error',
            confirmButtonColor: '#161616',
            html: '잘못된 요청입니다.',
            confirmButtonText: '확인',
          });
        }
      }
    } catch (err) {
      // console.log(err);
    }

    return Promise.reject(error);
  },
);

export default AXIOS;
