/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

// API
import { getUserSimpleInfo } from '@api/user/user';

// 유저정보 초기 객체
const initialUserInfo = {
  idx: '',
  id: '',
  name: '',
};

// ===================================================================
// [ Home Header ]
// ===================================================================
export default React.memo(function MainHeader(props) {
  // ===================================================================
  // [ Status ]
  // ===================================================================
  const [userInfo, setUserInfo] = useState(initialUserInfo);

  // ===================================================================
  // [ API ] 고객 기본 정보 가져오기 ( ID )
  // ===================================================================
  const getUserInfo = async () => {
    // Axios
    try {
      const { data } = await getUserSimpleInfo(); // Axios Post Request

      if (data.code === '0000') {
        setUserInfo({
          idx: data.data.mbIdx,
          id: data.data.mbId,
          name: data.data.mbFullName,
        });
      }
    } catch (error) {
      setUserInfo(initialUserInfo);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    getUserInfo();

    return () => {};
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <header
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <Container>
        <div className="header-flex">
          <h2 className="ellipsis">HIDC 통합 플랫폼</h2>
        </div>
        {/* <p className="id-title">
          <strong>ID</strong>
          <span className="id-number">{userInfo.id}</span>
        </p> */}
      </Container>
    </header>
  );
});
