/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { Header, CustomModal } from '@components';
import { checkPassword } from '@api/auth/auth';
import {
  sendCodeForEditPhoneNo,
  checkCodeForEditPhoneNo,
  editPasswordComplete,
  editPhoneNoComplete,
} from '@api/more/more';

import { useHistory } from 'react-router-dom';

// ===================================================================
// [ 내정보 수정 ]
// ===================================================================
export default React.memo(function InfoEdit(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const [inputPassword, setInputPassword] = useState('');
  const [nowPassword, setNowPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [number, setNumber] = useState('');
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isPhoneConfirmed, setIsPhoneConfirmed] = useState(false);
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false);
  const [isConfirmPasswordInvalid, setIsConfirmPasswordInvalid] =
    useState(false);
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(true);
  const [isPasswordDuplicate, setIsPasswordDuplicate] = useState(false);
  const [isRegexPassPassword, setIsRegexPassPassword] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [editPhoneBtn, setEditPhoneBtn] = useState(false);
  const [editPasswordBtn, setEditPasswordBtn] = useState(false);
  const [showcation, setShowcation] = useState(false);
  const [verificationSeq, setVerificationSeq] = useState('');

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(300);
  const [saveShow, setSaveShow] = useState(false); // 정보수정 모달
  const [sendShow, setSendShow] = useState(false); // 인증번호 발송
  const sendClose = () => setSendShow(false);
  const [checkShow, setCheckShow] = useState(false); // 인증완료
  const checkClose = () => setCheckShow(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalType, setModalType] = useState('');
  const [modalData, setModalData] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text, type = '', data = '' }) => {
    setModalText(text);
    setModalShow(true);
    setModalType(type);
    setModalData(data);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  const moveBack = () => {
    history.go(-1);
  };

  const movePage = pathname => {
    history.push({ pathname });
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  const PhoneRedirectBtn = () => {
    setEditPhoneBtn(true);
    setShowcation(true);
  };

  const CancelPhoneEdit = () => {
    setEditPhoneBtn(false);
    setShowcation(false);
  };

  const changePasswordBtn = () => {
    setEditPasswordBtn(true);
  };

  const cancelEditPwBtn = () => {
    setEditPasswordBtn(false);
  };

  const handleNowPasswordChange = e => {
    setNowPassword(e.target.value);
    setIsPasswordCorrect(false);
  };

  const handlePasswordChange = e => {
    setInputPassword(e.target.value);
    setIsPasswordInvalid(false);
  };

  const handleConfirmPasswordChange = e => {
    setConfirmPassword(e.target.value);
    setIsConfirmPasswordInvalid(false);
  };

  const handlePhone = e => {
    setPhone(e.target.value);
  };

  const handleNumber = e => {
    setNumber(e.target.value);
  };

  // 패스워드 검증
  const validatePassword = () => {
    checkPasswordDuplicate();
    passwordRegexTest();
  };

  const passwordRegexTest = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{4,12}$/;
    setIsRegexPassPassword(passwordRegex.test(inputPassword));
  };

  // 패스워드 확인 검즘
  const validateConfirmPassword = () => {
    setIsConfirmPasswordInvalid(inputPassword !== confirmPassword);
  };

  // 타이머 포맷팅
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const saveConfirm = () => {
    if (isPasswordConfirmed) {
      editPassword();
    }
    if (isPhoneConfirmed) {
      editPhone();
    }
    setSaveShow(false);
    openModal({ text: '정보 수정을 완료했습니다.', type: 'complete' });
  };

  const cancelSave = () => {
    setSaveShow(false);
  };

  // ===================================================================
  // [ API ]
  // ===================================================================
  const checkNowPassword = async () => {
    const param = { password: nowPassword };
    try {
      const { data } = await checkPassword(param);
      if (data.code === '0000') {
        setIsPasswordCorrect(true);
      } else {
        setIsPasswordCorrect(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkPasswordDuplicate = async () => {
    const param = { password: inputPassword };
    try {
      const { data } = await checkPassword(param);
      if (data.code === '0000') {
        setIsPasswordDuplicate(true);
      } else {
        setIsPasswordDuplicate(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendCode = async () => {
    if (!phone) {
      openModal({ text: '핸드폰 번호를 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      phoneNo: phone,
    };

    // Axios
    try {
      const { data } = await sendCodeForEditPhoneNo(params);
      if (data.code === '0000') {
        setIsVerificationCodeSent(true);
        setVerificationSeq(data.data.seq);
        setTimeRemaining(300);
        setStartTimer(true);
        openModal({
          text: `인증번호를 발송했습니다.
          인증번호가 오지 않으면 입력하신 정보를 다시 한번 더 확인해 주세요.`,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsVerificationCodeSent(false);
    }
  };

  const checkCode = async () => {
    if (isPhoneConfirmed) {
      openModal({ text: '이미 인증이 완료되었습니다.' });
      return;
    }

    if (!number) {
      openModal({ text: '인증번호를 입력해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      code: number,
      seq: verificationSeq,
    };

    // Axios
    try {
      const { data } = await checkCodeForEditPhoneNo(params);
      if (data.code === '0000') {
        setCheckShow(true);
        setIsPhoneConfirmed(true); // 인증 완료
        setIsSendButtonDisabled(true); // 인증번호 발송 버튼 비활성화
        setIsConfirmButtonDisabled(true); // 인증 버튼 비활성화
        openModal({
          text: `인증이 완료되었습니다.`,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsVerificationCodeSent(false);
    }
  };

  const editPassword = async () => {
    // JSON Param
    const params = {
      inputMbPassword: nowPassword,
      mbNewPassword: inputPassword,
      mbNewPasswordConfirm: confirmPassword,
    };

    // Axios
    try {
      const { data } = await editPasswordComplete(params);
      if (data.code === '0000') {
        setIsVerificationCodeSent(true);
        setVerificationSeq(data.data.seq);
        setSendShow(true);
        setStartTimer(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsVerificationCodeSent(false);
    }
  };

  const editPhone = async () => {
    // JSON Param
    const params = {
      certifySeq: verificationSeq, // 인증번호 발송 시 리턴받은 seq
      certifyCode: number, // 인증번호
      phoneNo: phone,
    };

    // Axios
    try {
      const { data } = await editPhoneNoComplete(params);
      if (data.code === '0000') {
        setIsVerificationCodeSent(true);
        setVerificationSeq(data.data.seq);
        setSendShow(true);
        setStartTimer(true);
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsVerificationCodeSent(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    let intervalId;

    if (startTimer) {
      intervalId = setInterval(() => {
        setTimeRemaining(prevTime => prevTime - 1);
      }, 1000);
    }

    if (timeRemaining === 0) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [startTimer, timeRemaining]);

  useEffect(() => {
    if (editPasswordBtn) {
      if (
        inputPassword !== '' &&
        confirmPassword !== '' &&
        !isPasswordInvalid &&
        !isConfirmPasswordInvalid &&
        isPasswordCorrect &&
        !isPasswordDuplicate &&
        isRegexPassPassword
      ) {
        setIsPasswordConfirmed(true);
      } else {
        setIsPasswordConfirmed(false);
      }
    } else {
      setIsPasswordConfirmed(false);
    }
  }, [nowPassword, inputPassword, confirmPassword, editPasswordBtn, isPasswordInvalid, isConfirmPasswordInvalid, isPasswordCorrect, isPasswordDuplicate, isRegexPassPassword]);

  useEffect(() => {
    if (editPasswordBtn && !showcation) {
      if (isPasswordConfirmed) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else if (showcation && !editPasswordBtn) {
      if (isPhoneConfirmed) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else if (editPasswordBtn && showcation) {
      if (isPasswordConfirmed && isPhoneConfirmed) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } else {
      setIsButtonDisabled(true);
    }
  }, [isPasswordConfirmed, isPhoneConfirmed, showcation, editPasswordBtn]);

  useEffect(() => {
    setIsPasswordInvalid(!isRegexPassPassword || isPasswordDuplicate);
  }, [isRegexPassPassword, isPasswordDuplicate]);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="info-edit">
      <Header title="내 정보 수정" />
      <Container className="container-between">
        <Form className="scroll mt-4">
          <Form.Group>
            <Form.Label>현재 비밀번호</Form.Label>
            <Form.Control
              type="password"
              placeholder="현재 비밀번호 입력"
              value={nowPassword}
              disabled={!editPasswordBtn}
              onBlur={checkNowPassword}
              isInvalid={!isPasswordCorrect}
              onChange={handleNowPasswordChange}
            />
            <Form.Control.Feedback type="invalid">
              비밀번호가 일치하지 않습니다.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label>새 비밀번호</Form.Label>
            <Form.Control
              type="password"
              placeholder="영문+숫자 4~12자 이내"
              value={inputPassword}
              onChange={handlePasswordChange}
              onBlur={validatePassword}
              disabled={!editPasswordBtn}
              isInvalid={isPasswordInvalid}
            />
            {isPasswordDuplicate && (
              <Form.Control.Feedback type="invalid">
                현재 비밀번호와 동일합니다.
              </Form.Control.Feedback>
            )}
            {!isRegexPassPassword && (
              <Form.Control.Feedback type="invalid">
                영문/숫자 조합(4~12자)
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Control
              type="password"
              placeholder="새 비밀번호 확인"
              disabled={!editPasswordBtn}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              onBlur={validateConfirmPassword}
              className="mt-2"
              isInvalid={isConfirmPasswordInvalid}
            />
            <Form.Control.Feedback type="invalid">
              비밀번호가 일치하지 않습니다.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="info-btn mt-2">
            {editPasswordBtn ? (
              <Button variant="outline-primary" onClick={cancelEditPwBtn}>
                취소
              </Button>
            ) : (
              <Button variant="outline-primary" onClick={changePasswordBtn}>
                변경
              </Button>
            )}
          </div>
          <Form.Group className="mt-4">
            <Form.Label>새 휴대전화 번호</Form.Label>
            <div className="grid-btn">
              <Form.Control
                type="text"
                placeholder="-없이 숫자만 입력"
                value={phone}
                onChange={handlePhone}
                disabled={!editPhoneBtn || isPhoneConfirmed}
              />
              {editPhoneBtn ? (
                <Button
                  variant="outline-primary"
                  disabled={isSendButtonDisabled}
                  onClick={sendCode}
                >
                  인증요청
                </Button>
              ) : (
                <Button variant="outline-primary" onClick={PhoneRedirectBtn}>
                  재인증
                </Button>
              )}
            </div>
            {showcation && (
              <>
                <div className="grid-btn mt-2">
                  <Form.Control
                    type="text"
                    value={number}
                    disabled={isPhoneConfirmed}
                    onChange={handleNumber}
                    placeholder="인증 번호 입력"
                  />
                  <Button
                    variant="outline-primary"
                    disabled={isConfirmButtonDisabled}
                    onClick={checkCode}
                  >
                    확인
                  </Button>
                </div>
                {!isPhoneConfirmed && (
                  <div className="grid-btn mt-2">
                    <p className="time-text">
                      남은시간 {formatTime(timeRemaining)}
                    </p>
                    <Button variant="outline-primary" onClick={CancelPhoneEdit}>
                      취소
                    </Button>
                  </div>
                )}
              </>
            )}
          </Form.Group>
          <div className="btn-bottom">
            <Button
              className="savebtn"
              disabled={isButtonDisabled}
              onClick={setSaveShow}
            >
              저장
            </Button>
          </div>
        </Form>
      </Container>
      {/** 정보수정 모달 */}
      <Modal
        size="sm"
        show={saveShow}
        onHide={() => setSaveShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        id="editmodal"
      >
        <Modal.Body>내 정보를 수정하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button className="modal-btn" onClick={cancelSave}>
            취소
          </Button>
          <Button className="modal-btn" onClick={saveConfirm}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 확인 모달 */}
      <CustomModal
        messageOnly={modalType !== 'delete-image'}
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
        onConfirm={
          modalType === 'denied'
            ? moveBack
            : modalType === 'invalid'
            ? () => movePage('/myinfo')
            : modalType === 'complete'
            ? () => movePage('/myinfo')
            : closeModal
        }
      />
    </main>
  );
});
