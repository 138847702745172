/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import { revokeMyKyc } from '@api/auth/auth';

// ===================================================================
// [ 인증센터 - KYC 등록 현황 상세 ]
// ===================================================================
export default React.memo(function IdenClear(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // KYC 정보
  const location = useLocation();
  const kycInfo = location.state?.kycInfo;

  // 중복 방지
  const [isSentRequest, setIsSentRequest] = useState(false);

  // 메세지 모달
  const [messageModalShow, setMessageModalShow] = useState(false);
  const [messageModalText, setMessageModalText] = useState('');
  const [messageModalType, setMessageModalType] = useState('');

  // 인증해제 모달
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 메세지 모달 출력
  const openMessageModal = ({ text, type = '' }) => {
    setMessageModalText(text);
    setMessageModalShow(true);
    setMessageModalType(type);
  };

  // 메세지 모달 닫기
  const closeMessageModal = () => {
    setMessageModalShow(false);
  };

  // 인증해제 모달 출력
  const openConfirmModal = ({ text }) => {
    setConfirmModalText(text);
    setConfirmModalShow(true);
  };

  // 인증해제 모달 닫기
  const closeConfirmModal = () => {
    setConfirmModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================

  // 신분증 유형 변환
  const convertKycType = type => {
    switch (type) {
      case 'id-card':
        return '주민등록증';
      case 'driver-license':
        return '주민등록증';
      default:
        return '';
    }
  };

  // KYC, 지갑 상태별 스타일 변환 ( null = 미등록, complete = 등록완료, cancle = 반려, stay 승인대기 )
  const convertOptions = status => {
    switch (status) {
      // 승인대기
      case 'REQ':
        return 'stay';
      // 승인
      case 'OK':
        return 'complete';
      // 반려
      case 'REJ':
        return 'cancle';
      default:
        return '';
    }
  };

  // 신분증 재등록
  const moveAddKyc = () => {
    history.push({ pathname: `/identify/check` });
  };

  // ===================================================================
  // [ API ] KYC 인증 해지
  // ===================================================================
  const dismissMyKyc = async () => {
    // 모달 닫기
    closeConfirmModal();

    // 중복 방지
    if (isSentRequest) {
      openMessageModal({ text: '진행중인 요청입니다.' });
      return;
    }

    // 승인 상태 확인
    if (kycInfo.status !== 'OK') {
      openMessageModal({ text: '승인된 KYC만 인증해제가 가능합니다.' });
      return;
    }

    // JSON 파라미터
    const params = {
      kycSeq: kycInfo.seq,
    };

    // 중복 방지
    setIsSentRequest(true);

    // Axios
    try {
      const { data } = await revokeMyKyc(params);

      // 성공
      if (data.code === '0000') {
        history.push({ pathname: `/status/authcenter` });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsSentRequest(false);
    }
  };

  // ===================================================================
  // [ useEffect ]
  // ===================================================================
  useEffect(() => {
    // KYC 정보 X > 접근제한
    if (!kycInfo) {
      openMessageModal({ text: '잘못된 접근입니다.', type: 'denied' });
    }

    return () => {};
  }, []);

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="iden-auth">
      {/* 헤더 */}
      <Header title="신분증 인증" />

      {/* 컨텐츠 */}
      <Container className="container-between">
        {/* 신분증 인증 정보 */}
        <Form className="scroll mt-4">
          <div className="iden-box">
            <div className="title-box">
              {/* 라벨 */}
              <p className="title-iden">실명확인 신분증 인증 정보</p>
              {/* 인증 상태 */}
              <p
                className={`label ${convertOptions(kycInfo && kycInfo.status)}`}
              >
                {kycInfo && kycInfo.statusHan}
              </p>
            </div>
            <div className="name-box">
              {/* 신분증 유형 */}
              <p className="regcard">
                {convertKycType(kycInfo && kycInfo.type)}
              </p>
              {/* 이름 */}
              <p className="name-card">{kycInfo && kycInfo.mbFullName}</p>
            </div>

            {/* 상세내용 - '반려' Only */}
            {kycInfo && kycInfo.status === 'REJ' && (
              <div className="reasonbox">
                {/* 라벨 */}
                <p className="reason-title">반려 사유</p>

                {/* 반려 사유 */}
                <p className="reason-body">{kycInfo && kycInfo.memo}</p>
              </div>
            )}
          </div>
        </Form>

        {/* 인증 헤제 버튼 */}
        <div className="btn-area fix-bottom">
          {kycInfo && kycInfo.status !== 'REJ' && (
            <Button
              size="lg"
              onClick={() =>
                openConfirmModal({ text: '신분증 인증을 해제하시겠습니까?' })
              }
            >
              인증 해제하기
            </Button>
          )}
          {kycInfo && kycInfo.status === 'REJ' && (
            <Button size="lg" onClick={moveAddKyc}>
              재등록
            </Button>
          )}
        </div>
      </Container>

      {/* 인증해제 모달 */}
      <CustomModal
        messageOnly={false}
        headerText="인증 해제"
        contentText={confirmModalText}
        display={confirmModalShow}
        onHide={closeConfirmModal}
        onConfirm={dismissMyKyc}
      />

      {/* 메세지 모달 */}
      <CustomModal
        contentText={messageModalText}
        headerDisplay={false}
        display={messageModalShow}
        onHide={
          messageModalType === 'denied'
            ? () => history.go(-1)
            : closeMessageModal
        }
      />
    </main>
  );
});
