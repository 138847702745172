/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useRef, useState, useTransition, useEffect } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { NoticeHeader, CustomModal } from '@components';
import { useHistory, useLocation } from 'react-router-dom';
import { getNoticeDetail, getNextNotice, getPrevNotice } from '@api/more/more';

// ===================================================================
// [ 공지사항 상세 ]
// ===================================================================
export default React.memo(function noticeDetailPage(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();
  const location = useLocation();
  const [notice, setNotice] = useState([]);
  const [prevNotice, setPrevNotice] = useState([]);
  const [nextNotice, setNextNotice] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const seq = searchParams.get('seq');

  // ===================================================================
  // [ Util ]
  // ===================================================================
  const noticeDetail = async () => {
    try {
      const { data } = await getNoticeDetail(seq);
      if (data.code === '0000') {
        setNotice(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const nextNoticeInfo = async () => {
    try {
      const { data } = await getNextNotice(seq);
      if (data.code === '0000') {
        setNextNotice(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const prevNoticeInfo = async () => {
    try {
      const { data } = await getPrevNotice(seq);
      if (data.code === '0000') {
        setPrevNotice(data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // ===================================================================
  // [ useEffect ] 페이지 로드 시 공지사항 상세 출력
  // ===================================================================
  useEffect(async () => {
    await noticeDetail();
    await nextNoticeInfo();
    await prevNoticeInfo();
  }, [seq]);

  useEffect(() => {
    handleScrollToTop();
  }, [seq]);

  return (
    <main id="notice-detail">
      <NoticeHeader title="공지사항" isCloseBtn={false} />
      <Container className="container-between">
        <Form className="scroll">
          <div className="detail-titlebox">
            <div className="detail-box">
              <h6>{notice.title}</h6>
              <div className="title-flex">
                <p>{notice.regDate}</p>
                <p className="line-border">|</p>
                <p>조회수 {notice.viewCnt}</p>
              </div>
            </div>
          </div>
          <div
            className="detail-body"
            dangerouslySetInnerHTML={{ __html: notice.contents }}
          />
          {!prevNotice.isFirst && (
            <div
              className="nb-listbox"
              onClick={() => {
                history.push({
                  pathname: `/myinfo/notice/detail`,
                  search: `?seq=${prevNotice.seq}`,
                });
              }}
            >
              <p className="nb-list">이전글</p>
              <p className="nb-title">{prevNotice.title}</p>
            </div>
          )}
          {!nextNotice.isLast && (
            <div
              className="nb-listbox"
              onClick={() => {
                history.push({
                  pathname: `/myinfo/notice/detail`,
                  search: `?seq=${nextNotice.seq}`,
                });
              }}
            >
              <p className="nb-list">다음글</p>
              <p className="nb-title">{nextNotice.title}</p>
            </div>
          )}
          <div className="list-btndiv">
            <Button
              className="lsit-btn"
              variant="outline-primary"
              onClick={() => {
                history.push({ pathname: `/myinfo/notice` });
              }}
            >
              목록
            </Button>
          </div>
        </Form>
      </Container>
    </main>
  );
});
