/* eslint-disable no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Custom Component
import { Header, CustomModal } from '@components';

// API
import {
  sendPhoneVerificationCode,
  checkPhoneVerificationCode,
  findPasswordComplete,
} from '@api/auth/openAuth';

// ===================================================================
// [ 비밀번호 찾기 ]
// ===================================================================
export default React.memo(function FindId(props) {
  // ===================================================================
  // [ State ]
  // ===================================================================
  const history = useHistory();

  // 입력값
  const [id, setId] = useState('');
  const [phone, setPhone] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationSeq, setVerificationSeq] = useState('');

  // 동작
  const [isSent, setIsSent] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState('');

  // ===================================================================
  // [ Modal ]
  // ===================================================================

  // 모달 출력
  const openModal = ({ text }) => {
    setModalText(text);
    setModalShow(true);
  };

  // 모달 닫기
  const closeModal = () => {
    setModalShow(false);
  };

  // ===================================================================
  // [ Util ]
  // ===================================================================
  // ID(Email) 입력
  const idInputHandler = value => {
    setId(value);
  };

  // 전화번호 입력
  const phoneInputHandler = value => {
    setPhone(value);
  };

  // 인증코드 입력
  const codeInputHandler = value => {
    setVerificationCode(value);
  };

  // ===================================================================
  // [ API ] 인증번호 발송
  // ===================================================================
  const sendSmsCode = async () => {
    // ID(Email) 확인
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(id)) {
      openModal({ text: '잘못된 형식의 아이디입니다.' });
      return;
    }

    if (!phone) {
      // Input 확인
      openModal({ text: '핸드폰 번호를 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      phoneNo: phone,
    };

    // Axios
    try {
      const { data } = await sendPhoneVerificationCode(params);

      // 성공
      if (data.code === '0000') {
        setIsSent(true); // 중복 방지
        setVerificationSeq(data.data.seq); // 발송 Seq
        openModal({ text: '인증번호가 발송되었습니다.' });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsSent(false);
    }
  };

  // ===================================================================
  // [ API ] 인증번호 확인
  // ===================================================================
  const confirmSmsCode = async () => {
    // Input 확인
    if (!isSent || !verificationSeq || !verificationCode) {
      openModal({ text: '인증코드를 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      seq: verificationSeq,
      code: verificationCode,
    };

    // Axios
    try {
      const { data } = await checkPhoneVerificationCode(params);

      // 성공
      if (data.code === '0000') {
        setIsConfirmed(true); // 중복 방지
        openModal({ text: '인증번호가 확인되었습니다.' });
      }
      // 실패
      else {
        throw new Error();
      }
    } catch (error) {
      setIsConfirmed(false);
    }
  };

  // ===================================================================
  // [ API ] 모바일 인증 완료
  // ===================================================================
  const completeFindId = async () => {
    // Input 확인
    if (
      !isConfirmed ||
      !id ||
      !phone ||
      !verificationCode ||
      !verificationSeq
    ) {
      openModal({ text: '입력값을 확인해주세요.' });
      return;
    }

    // JSON Param
    const params = {
      id,
      phoneNo: phone,
      certificationSeq: verificationSeq,
      certificationCode: verificationCode,
    };

    // Axios
    try {
      const { data } = await findPasswordComplete(params);

      if (data.code === '0000') {
        // 결과 확인 페이지 이동
        history.push({
          pathname: `/auth/pwdresetlink`,
          state: { phone },
        });
      }
    } catch (error) {
      // ...
    }
  };

  // ===================================================================
  // [ return ]
  // ===================================================================
  return (
    <main id="find-pwd">
      {/* 헤더 */}
      <Header title="비밀번호 찾기" />

      {/* 컨텐츠 */}
      <Container className="container-between">
        <Form className="scroll mt-4">
          {/* 아이디(이메일) */}
          <Form.Group>
            <Form.Label>아이디(이메일)</Form.Label>
            <Form.Control
              type="email"
              name="id"
              placeholder="아이디(이메일) 입력"
              value={id}
              disabled={isSent}
              onChange={e => idInputHandler(e.target.value)}
              maxLength={30}
            />
          </Form.Group>

          {/* 모바일 인증 */}
          <Form.Group className="mt-4">
            <Form.Label>휴대전화 번호</Form.Label>

            {/* 전화번호 */}
            <div className="grid-btn">
              <Form.Control
                type="text"
                placeholder="-없이 숫자만 입력"
                value={phone}
                disabled={isSent}
                onChange={e => phoneInputHandler(e.target.value)}
                maxLength={11}
              />
              <Button
                variant="outline-primary"
                disabled={!id || !phone || isSent}
                onClick={sendSmsCode}
              >
                인증번호
              </Button>
            </div>

            {/* 인증번호 */}
            <div className="grid-btn mt-2">
              <Form.Control
                type="text"
                placeholder="인증 번호 입력"
                value={verificationCode}
                disabled={isConfirmed}
                onChange={e => codeInputHandler(e.target.value)}
                maxLength={6}
              />
              <Button
                variant="outline-primary"
                disabled={!id || !isSent || isConfirmed}
                onClick={confirmSmsCode}
              >
                확인
              </Button>
            </div>
          </Form.Group>
        </Form>

        {/* 확인 */}
        <div className="btn-area fix-bottom">
          <Button size="lg" disabled={!isConfirmed} onClick={completeFindId}>
            확인
          </Button>
        </div>
      </Container>

      {/* 모달 */}
      <CustomModal
        contentText={modalText}
        headerDisplay={false}
        display={modalShow}
        onHide={closeModal}
      />
    </main>
  );
});
